import React from 'react';
import { Loader } from './Loader';
import { useApp } from '../../contexts/AppContext';
import './AppLoader.scss';

export const AppLoader = () => {
  const { loading } = useApp();

  return <Loader loading={loading} />;
};

import React from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { Modal, useModal } from '../../../../components/Modal';
import './BillingHistory.scss';
import { GET_BILLING_HISTORY, GET_INVOICE_PDF } from '../../../../services/ApolloClient';
import { formatTimestampDateString } from '../../../../utils';

interface InvoiceItem {
  amount: string;
  id: string;
  name: string;
  timestamp: number;
}

const LinkButton = ({ invoiceId }: { invoiceId: string }) => {
  const [getPDFLink, { loading }] = useLazyQuery(GET_INVOICE_PDF, {
    variables: {
      invoiceId,
    },
  });

  const handleDownload = async () => {
    const PDFLinkRes = await getPDFLink();
    const PDFLink = PDFLinkRes?.data?.invoiceAsPdf?.downloadUrl;
    // workaround for safari
    setTimeout(() => {
      window.open(PDFLink, '_blank');
    });
  };

  return (
    <div className="link-wrapper">
      {!loading ? (
        <button type="button" className="link-btn" onClick={handleDownload}>
          Download
        </button>
      ) : (
        <div className="spinner-wrapper">
          <div className="spinner" />
        </div>
      )}
    </div>
  );
};
export const BillingHistory = () => {
  const { isOpen, closeModal, openModal } = useModal();
  const { data } = useQuery(GET_BILLING_HISTORY);

  if (!data?.billingHistory.length) return null;

  return (
    <>
      <span className="action-link" onClick={openModal}>
        Billing history
      </span>
      <Modal isOpen={isOpen} onClose={closeModal}>
        <div className="billing-history-container">
          <h2 className="billing-history-title">Billing History</h2>

          <ul className="billing-history-list">
            {data?.billingHistory?.map((item: InvoiceItem) => (
              <li key={item?.id}>
                <div className="billing-history-item">
                  <div className="item-content">
                    <span>{item?.name}</span>
                    <span>&#36;{item?.amount}</span>
                    <span>{formatTimestampDateString(item?.timestamp)}</span>
                  </div>
                  <LinkButton invoiceId={item?.id} />
                </div>
              </li>
            )) ?? 'No data received'}
          </ul>
        </div>
      </Modal>
    </>
  );
};

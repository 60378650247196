import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import './ReviewsSlider.scss';
import { ReactComponent as ArrowLeft } from '../../assets/images/arrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../assets/images/arrowRight.svg';

const properties = {
  transitionDuration: 500,
  indicators: true,
  autoplay: false,
  infinite: true,
  arrows: true,
  prevArrow: (
    <button type="button" className="nav-buttons">
      <ArrowLeft />
    </button>
  ),
  nextArrow: (
    <button type="button" className="nav-buttons">
      <ArrowRight />
    </button>
  ),
};
const reviews = [
  {
    review:
      '"The core support wear is easy to put on and wear. I particularly like to wear it while working or sitting for a long time, as it reminds me to have good posture, keep my core engaged and be thoughtful about my movements."',
    reviewer: 'Jackie',
  },
  {
    review:
      '"This item offers great comfortable compression that you can wear throughout your day, allowing for more energy through increased support. I suggest sizing down if you are between sizes. I had to return to get a smaller size, but the return process was easy."',
    reviewer: 'Laura',
  },
  {
    review:
      '"I’m enjoying it! It’s a lot thinner and not as rigid as I thought which is great because the support is there and I feel very comfortable wearing it throughout the day. Personally, it takes me a bit of time to put it on but glad to have been taught to do core compressions while doing so. Overall would recommend."',
    reviewer: 'Ana',
  },
  {
    review:
      '"So thrilled to see an EMBody product - I wanted to buy a support product but so weary of buying something fake/cheap off Amazon at risk of worsening my DR. Quality is excellent, I love the colour (blush) and I soon forget I’m even wearing it."',
    reviewer: 'Olivia',
  },
  {
    review:
      '"After birthing 4 kids and going back to PT numerous times for DR and wearing several kinds of splints, corsets, and abdominal supports, I will say that this one has been the most comfortable, supportive, and flattering. I love how all the hooks allow for flexibility in how wide or narrow I can make it and the whole design is sleek and imperceptible under clothing. It is also comfortable to wear and put on. It is on the pricier side and well worth it, considering how many other similar products I own but don’t like using (so those were cheaper but not a good use of my money since I don’t use them!)"',
    reviewer: 'Bernardine',
  },
  {
    review:
      '"Great core support! I’ve started wearing this for a few hours each day (gradually building up to wearing it longer) while also doing the Every Mother Reclaim Classic exercise program. The core support band is a great reminder to engage my core. Highly recommend!"',
    reviewer: 'Larisa',
  },
];

const ReviewsSlider = () => {
  return (
    <div className="reviews-slider-container">
      <div className="reviews-slider-container-wrap">
        <h3>Customer Reviews</h3>
        <Slide {...properties}>
          {reviews.map(review => (
            <div className="slide-box" key={review.reviewer}>
              <p>{review.review}</p>
              <p>{review.reviewer}</p>
            </div>
          ))}
        </Slide>
      </div>
    </div>
  );
};

export default ReviewsSlider;

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { forgotPasswordFields } from '../../services/Constants/FormConstants';
import Form from '../../components/Form/Form';
import { EDataKey, TData } from '../../types/forms';
import { RESET_PASSWORD } from '../../services/ApolloClient';
import { useApp } from '../../contexts';
import { ErrorsService } from '../../services/ErrorsService';

function ForgotPassword() {
  const fields = forgotPasswordFields;
  const [formError, setFormError] = useState({});
  const [reset, { data: resetData }] = useMutation(RESET_PASSWORD);
  const { setLoading } = useApp();
  const location = useLocation();
  const [infoMessage, setInfoMessage] = useState<string | null>(null);

  useEffect(() => {
    if (resetData) {
      setInfoMessage('An email has been sent with instructions to reset your password.');
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    else if (location.state?.fromChange) {
      setInfoMessage(
        'Your password reset link appears to be invalid. Please request a new link below.',
      );
    } else {
      setInfoMessage(null);
    }
  }, [location, resetData]);

  const handleReset = async (formData: Partial<TData>) => {
    try {
      setLoading(true);
      setFormError({});
      await reset({
        variables: {
          name: formData?.email?.trim(),
        },
      });
      setLoading(false);
    } catch (e: any) {
      ErrorsService.message(`[ApolloError RESET_PASSWORD]: ${e.message}`);
      setLoading(false);
      setFormError({
        message: e.message,
        fields: [EDataKey.email],
      });
    }
  };

  return (
    <>
      {infoMessage && <div className="info-message">{infoMessage}</div>}
      <div className="register-login-container">
        <Helmet>
          <title>Forgot Password | Every Mother</title>
        </Helmet>
        <div className="register-login-content">
          <Form
            title="Reset Password"
            fields={fields}
            handleForm={handleReset}
            formError={formError}
            withLabel={false}
            buttonLabel="Request New Password"
          />
          <div className="form-links">
            <div>
              <p>Back to &nbsp;</p>
              <Link className="navigate-link" to="/login/">
                log in
              </Link>
            </div>
            <p>Not a Member? &nbsp;</p>
            <a className="navigate-link" href="/members/join/">
              join now
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;

import React from 'react';
import { Link } from 'react-router-dom';
import { ExternalIdentifierBlock } from './ExternalIdentifierBlock';
import { useAuth, useError } from '../../../../contexts';
import { EMembershipPlatform, EMembershipStatus, TMembershipData, UserAccountData } from '../types';
import { navigateWithReload } from '../../../../utils';

const IAP_CANCEL_MESSAGE =
  'You need to cancel your subscription within the Apple App Store or Google Play Store before deleting your account.';

interface UserAccountSectionProps {
  userData: UserAccountData;
  membershipData: TMembershipData;
}

export const UserAccountSection = ({
  userData: { username, email, firstName, lastName, isPasswordSet, externalIdentifiers },
  membershipData,
}: UserAccountSectionProps) => {
  const { logout } = useAuth();
  const { showError } = useError();

  const isSocialLogin = externalIdentifiers?.length !== 0;

  const deleteAccount = () => {
    const isInactiveSubscription: boolean = [
      EMembershipStatus.cancelled,
      EMembershipStatus.expired,
    ].includes(membershipData?.status);

    const isMobileSubscription: boolean = [
      EMembershipPlatform.iap,
      EMembershipPlatform.revenuecat,
    ].includes(membershipData?.platform);

    if (isMobileSubscription && !isInactiveSubscription) {
      showError({ message: IAP_CANCEL_MESSAGE, buttonText: 'Ok' });
    } else {
      navigateWithReload('/app/delete-account/');
    }
  };

  return (
    <div className="user-info-container">
      <h3>My Account</h3>
      <div className="user-info">
        {isSocialLogin ? (
          <>
            {firstName && <p>First Name: {firstName}</p>}
            {lastName && <p>Last Name: {lastName}</p>}
            {isPasswordSet && <p>Email: {email}</p>}
            {isPasswordSet && <p>Username: {username}</p>}
          </>
        ) : (
          <>
            {username && <p>Username: {username}</p>}
            {email && <p>Email: {email}</p>}
          </>
        )}
      </div>
      <div className="account-links">
        <Link className="action-link" to="/app/edit-profile/">
          edit profile
        </Link>
        <button type="button" className="action-link" onClick={logout}>
          log out
        </button>
        <button type="button" className="action-link" onClick={deleteAccount}>
          Delete account
        </button>
      </div>

      {isSocialLogin && <ExternalIdentifierBlock providersData={externalIdentifiers} />}
    </div>
  );
};

import React from 'react';
import './ErrorMessage.scss';

interface IErrorMessageTypes {
  errorMessage: string;
}
export default function ErrorMessage({ errorMessage }: Readonly<IErrorMessageTypes>) {
  if (!errorMessage) return null;
  return <p className="error-message">{errorMessage}</p>;
}

export enum AuthProvider {
  GOOGLE = 'GOOGLE',
  FACEBOOK = 'FACEBOOK',
  APPLE = 'APPLE',
}

export enum OAuthCallbackError {
  EMAIL_MISSING = 'email_missing',
  EMAIL_ALREADY_EXISTS = 'email_already_exists',
  UNKNOWN = 'unknown',
}

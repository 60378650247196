import jwtDecode, { JwtPayload } from 'jwt-decode';
import cookie from 'react-cookies';
import { refreshRequest } from '../services/ApolloClient';

type customJwtPayload = JwtPayload & { uid?: string; redirect_url?: string };

const setToken = (value: string) => {
  if (value) {
    const data = { token: value };
    localStorage.setItem('em-client-jwt', JSON.stringify(data));
    // for wordpress
    localStorage.setItem('jwt', value);
    cookie.save('em-client-jwt', value, {
      path: '/',
      expires: new Date(Date.now() + 24 * 14 * 3600 * 1000),
      secure: false,
      httpOnly: false,
    });
  }
};

const logout = () => {
  window.location.href = `${window.location.origin}/logout/`;
};

const removeToken = () => {
  localStorage.removeItem('em-client-jwt');
  localStorage.removeItem('jwt');
};

const validateChangePasswordToken = (token: string | null) => {
  if (token) {
    const decoded = jwtDecode<customJwtPayload>(token);
    return (decoded?.exp ?? 0) * 1000 > new Date().getTime();
  }
  return false;
};

const refresh = async (token: string) => {
  try {
    if (validateChangePasswordToken(token)) {
      const res = await refreshRequest(token);
      if (res.success) {
        setToken(res.refreshToken.token);
        return res.refreshToken.token;
      }
      return null;
    }
    return token;
  } catch (e) {
    console.log(e);
    return null;
  }
};

const getToken = () => {
  const data = localStorage.getItem('em-client-jwt');
  if (data) {
    const { token } = JSON.parse(data);
    if (validateChangePasswordToken(token)) {
      return token;
    }
    logout();
  }

  if (window.location.pathname.includes('reset-password')) {
    return null;
  }

  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('token');
};

const refreshToken = async () => {
  const token = getToken();
  if (token) {
    await refresh(token);
    return true;
  }
  return false;
};

const decodeToken = (token: string | null) => {
  if (token) {
    return jwtDecode<customJwtPayload>(token);
  }
  return null;
};

const getUserId = () => {
  const data = localStorage.getItem('em-client-jwt');
  if (data) {
    const { token } = JSON.parse(data);
    const decoded = jwtDecode<customJwtPayload>(token);
    return String(decoded?.uid);
  }
  return null;
};

export {
  setToken,
  getToken,
  validateChangePasswordToken,
  refreshToken,
  removeToken,
  decodeToken,
  getUserId,
};

import { EDataKey, FieldTemplates } from '../../types/forms';

export const PASSWORD_MATCH_ERROR_MESSAGE = 'The passwords do not match';
export const PASSWORD_CONFIRM_ERROR_MESSAGE = 'Please enter a confirm password';
export const PASSWORD_ERROR_MESSAGE = 'Please enter a password';

export const loginFields = [
  {
    label: 'Email',
    key: EDataKey.email,
    type: 'text',
    placeholder: 'Enter your username or email',
    error: 'Please enter your username or email',
  },
  {
    label: 'Password',
    key: EDataKey.password,
    type: 'password',
    placeholder: 'Enter your password',
    error: 'Please enter your password',
  },
];

export const registerFields = [
  {
    label: 'Email',
    key: EDataKey.email,
    type: 'email',
    placeholder: 'Enter your email',
    error: 'Please enter an email address',
  },
  {
    label: 'Password',
    key: EDataKey.password,
    type: 'password',
    placeholder: 'Enter your password',
    error: PASSWORD_ERROR_MESSAGE,
  },
  {
    label: 'Confirm password',
    key: EDataKey.confirmPassword,
    type: 'password',
    placeholder: 'Re-enter your password',
    error: PASSWORD_CONFIRM_ERROR_MESSAGE,
  },
];

export const changePasswordWithKeyFields = [
  {
    label: 'New Password',
    key: EDataKey.newPassword,
    type: 'password',
    placeholder: 'Enter new password',
    error: PASSWORD_ERROR_MESSAGE,
  },
  {
    label: 'Confirm password',
    key: EDataKey.confirmPassword,
    type: 'password',
    placeholder: 'Confirm new password',
    error: PASSWORD_CONFIRM_ERROR_MESSAGE,
  },
];

export const changePasswordFields = [
  {
    label: 'Current Password',
    key: EDataKey.currentPassword,
    type: 'password',
    placeholder: 'Enter current password',
    error: PASSWORD_ERROR_MESSAGE,
  },
  {
    label: 'New Password',
    key: EDataKey.newPassword,
    type: 'password',
    placeholder: 'Enter new password',
    error: PASSWORD_ERROR_MESSAGE,
  },
  {
    label: 'Confirm password',
    key: EDataKey.confirmPassword,
    type: 'password',
    placeholder: 'Confirm new password',
    error: PASSWORD_CONFIRM_ERROR_MESSAGE,
  },
];

export const forgotPasswordFields = [
  {
    label: 'Email',
    key: EDataKey.email,
    type: 'text',
    placeholder: 'Enter your username or email',
    error: 'Please enter your username or email',
  },
];

export const passwordConfirmationFields = [
  {
    label: 'Password',
    key: EDataKey.password,
    type: 'password',
    placeholder: 'Enter your password',
    error: 'Please enter your password',
  },
];

export const defaultEditFields = [
  EDataKey.firstName,
  EDataKey.lastName,
  EDataKey.email,
  EDataKey.username,
];

export const socialEditFields = [EDataKey.firstName, EDataKey.lastName, EDataKey.email];

export const fieldTemplates = {
  [EDataKey.firstName]: {
    label: 'First Name',
    key: EDataKey.firstName,
    type: 'text',
    placeholder: 'First Name',
  },
  [EDataKey.lastName]: {
    label: 'Last Name',
    key: EDataKey.lastName,
    type: 'text',
    placeholder: 'Last Name',
  },
  [EDataKey.email]: {
    label: 'Email',
    key: EDataKey.email,
    type: 'email',
    placeholder: 'Email',
  },
  [EDataKey.username]: {
    label: 'Username',
    key: EDataKey.username,
    type: 'text',
    placeholder: 'Username',
  },
  [EDataKey.password]: {
    label: 'Password',
    key: EDataKey.password,
    type: 'password',
    placeholder: 'Enter your password',
    error: PASSWORD_ERROR_MESSAGE,
  },
  [EDataKey.confirmPassword]: {
    label: 'Confirm Password',
    key: EDataKey.confirmPassword,
    type: 'password',
    placeholder: 'Re-enter your password',
    error: PASSWORD_CONFIRM_ERROR_MESSAGE,
  },
} as FieldTemplates;

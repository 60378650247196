import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import './Slider.scss';
import { ReactComponent as ArrowLeft } from '../../assets/images/arrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../assets/images/arrowRight.svg';

const properties = {
  transitionDuration: 500,
  infinite: true,
  arrows: true,
  autoplay: false,
  prevArrow: (
    <button type="button" className="nav-buttons">
      <ArrowLeft />
    </button>
  ),
  nextArrow: (
    <button type="button" className="nav-buttons">
      <ArrowRight />
    </button>
  ),
};

export const Slider = ({ images }: { images: Array<string> }) => {
  return (
    <div className="slider-container">
      <Slide {...properties}>
        {images.map(img => (
          <div className="slide-box" key={img}>
            <img src={img} alt="slide_image" />
          </div>
        ))}
      </Slide>
    </div>
  );
};

import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import * as Sentry from '@sentry/react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import client from './services/ApolloClient/ApolloClient';
import { AuthProvider, ProductsProvider, AppProvider, ErrorProvider } from './contexts';
import { FeatureToggleProvider } from './services/FeatureToggle';
import { initializeFontLoading } from './utils';
import './index.scss';

const { REACT_APP_SENTRY_DSN, REACT_APP_ENV } = process.env;

if (REACT_APP_SENTRY_DSN && REACT_APP_ENV) {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    environment: REACT_APP_ENV,
  });
}

// Ensure font "ValueSans" is loaded before rendering
initializeFontLoading();

ReactDOM.render(
  <React.StrictMode>
    <FeatureToggleProvider>
      <ErrorProvider>
        <BrowserRouter>
          <CookiesProvider>
            <HelmetProvider>
              <ApolloProvider client={client}>
                <AppProvider>
                  <AuthProvider>
                    <ProductsProvider>
                      <App />
                    </ProductsProvider>
                  </AuthProvider>
                </AppProvider>
              </ApolloProvider>
            </HelmetProvider>
          </CookiesProvider>
        </BrowserRouter>
      </ErrorProvider>
    </FeatureToggleProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// it's necessary for tracking GA
import { OrganizationSignupDetails } from '../types/enterprise';

export const navigateWithReload = (route: string) => {
  window.location.href = `${window.location.origin}${route}`;
};

export const navigateToExternalRoute = (route: string) => {
  window.location.href = route;
};

export const validateUrl = (url: string | null) => {
  if (url?.includes(window.location.origin)) {
    return url;
  }
  return null;
};

export const getOrgDetailsFromQueryString = (
  searchParams: URLSearchParams,
): OrganizationSignupDetails | null => {
  const organizationId = Number(searchParams.get('org_id'));
  const organizationToken = searchParams.get('org_token');
  if (organizationId && organizationToken) {
    return {
      id: organizationId,
      token: decodeURIComponent(organizationToken),
    };
  }
  return null;
};

export const getLocalStorageItem = (key: string) => {
  const value = localStorage.getItem(key);
  return value ? JSON.parse(value) : value;
};

export const cleanLocalStorageItems = (items: Array<string>): void => {
  items?.forEach(item => localStorage.removeItem(item));
};

export const initializeFontLoading = async (): Promise<void> => {
  try {
    if (document?.fonts?.load) {
      await document.fonts.load('1em Value');
      await document.fonts.load('1em Vanitas');
    }
  } catch {
    console.warn('Font loading failed, falling back to system fonts');
  } finally {
    document.body.classList.add('fonts-loaded');
  }
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

import React, { useState } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import { SET_PASSWORD, UPDATE_PROFILE } from '../../../services/ApolloClient';
import { API } from '../../../services/API/BaseAPI';
import {
  defaultEditFields,
  fieldTemplates,
  PASSWORD_MATCH_ERROR_MESSAGE,
  socialEditFields,
} from '../../../services/Constants/FormConstants';
import { useApp } from '../../../contexts';
import Form from '../../../components/Form/Form';
import { EDataKey, TData } from '../../../types/forms';
import { UserAccountData } from '../../MyAccount/components/types';

interface EditProfileFormProps {
  userInfo: UserAccountData;
}

const SET_PASSWORD_BUTTON_TEXT = 'Set a password to enable email login as a backup method.';

export const EditProfileForm = ({ userInfo }: EditProfileFormProps) => {
  const isSocialLogin = userInfo?.externalIdentifiers?.length !== 0;
  const [formError, setFormError] = useState({});
  const [isPasswordSet, setIsPasswordSet] = useState(userInfo?.isPasswordSet);
  const [fields, setFields] = useState(
    isSocialLogin && !isPasswordSet ? socialEditFields : defaultEditFields,
  );
  const [uploadProfile] = useMutation(UPDATE_PROFILE);
  const [setPassword] = useMutation(SET_PASSWORD);
  const { setLoading } = useApp();

  const getFieldsData = (keys: Array<EDataKey>) => {
    return keys.map((key: EDataKey) => {
      if (isSocialLogin && key === EDataKey.email) {
        return { ...fieldTemplates[key], placeholder: userInfo?.email, isDisabled: true };
      }
      return fieldTemplates[key];
    });
  };

  const handleSubmit = async (formData: Partial<TData>) => {
    setLoading(true);

    if (formData.password !== formData.confirmPassword) {
      setFormError({
        message: PASSWORD_MATCH_ERROR_MESSAGE,
        fields: [EDataKey.password, EDataKey.confirmPassword],
      });
      setLoading(false);
      return;
    }

    try {
      await uploadProfile({
        variables: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          username: formData?.username?.trim(),
          email: formData?.email?.trim(),
        },
      });
      if (formData.password && formData.confirmPassword) {
        await setPassword({
          variables: {
            newPassword: formData.password,
            newPasswordConfirm: formData.confirmPassword,
          },
        });
      }
      await API.post('auth-cookie');
      window.location.replace('/app/account/');
      setLoading(false);
    } catch (error: unknown) {
      if (error instanceof ApolloError) {
        if (error.message) {
          setFormError({
            message: error.message,
            fields: [
              EDataKey.firstName,
              EDataKey.lastName,
              EDataKey.username,
              EDataKey.email,
              EDataKey.password,
              EDataKey.confirmPassword,
            ],
          });
        }
      }
      setLoading(false);
    }
  };

  const showPasswordBlock = () => {
    setIsPasswordSet(true);
    setFields(prevState => [...prevState, EDataKey.password, EDataKey.confirmPassword]);
  };

  return (
    <Form
      fields={getFieldsData(fields)}
      formData={userInfo}
      handleForm={handleSubmit}
      formError={formError}
      buttonLabel="Update Profile"
    >
      {!isPasswordSet && (
        <button type="button" className="btn-link" onClick={showPasswordBlock}>
          {SET_PASSWORD_BUTTON_TEXT}
        </button>
      )}
    </Form>
  );
};

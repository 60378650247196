export const EVENT = {
  QUESTION_ANSWERED: 'Question Answered',
  REFER_FRIEND_INITIATED: 'Refer A Friend Initiated',
};

export const sanitizeObject = (obj: any): any => {
  if (obj === null || obj === undefined || obj === '') {
    return null;
  }

  if (typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.length === 0 ? null : obj.map(sanitizeObject);
  }

  const sanitized: any = {};
  Object.keys(obj).forEach(key => {
    const value = sanitizeObject(obj[key]);
    if (value !== null) {
      sanitized[key] = value;
    }
  });

  return Object.keys(sanitized).length === 0 ? null : sanitized;
};

import React, { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useFlag } from '@unleash/proxy-client-react';
import {
  GET_PRECANCEL_URL,
  SELF_SERVE_PORTAL,
  MIGRATE_PMPRO,
  UNCANCEL_SUBSCRIPTION,
  CHECKOUT,
} from '../../../../services/ApolloClient';
import { formatTimestampDateString, navigateWithReload } from '../../../../utils';
import {
  EMembershipGroup,
  EMembershipPlatform,
  EMigrationStrategy,
  TMembershipData,
} from '../types';
import {
  getDateTitle,
  getLinksForChargebee,
  getLinksForMobile,
  getLinksForPmpro,
  getMethods,
  getNameByGroup,
  getStatusState,
} from './helpers';
import { useApp, useError } from '../../../../contexts';
import { InfoModal } from '../../../../components/InfoModal';
import { useModal } from '../../../../components/Modal';
import './MembershipTable.scss';

const UPGRADE_PAYPAL = {
  title: 'Upgrade Membership',
  info1:
    'In order to upgrade your membership, you will be prompted to enter your card details on the next screen.',
  info2: 'Please note that you will be credited for any unused time on your current plan.',
};

const UNCANCEL_PAYPAL = {
  title: 'Uncancel Membership',
  info1:
    'In order to uncancel your membership, you will be prompted to enter your card details on the following screen.',
  info2:
    'Please be aware that your next payment will only be charged at the end of the current billing period.',
};

interface IMembershipTablePropTypes {
  data: TMembershipData | null;
}

export function MembershipTable({ data }: Readonly<IMembershipTablePropTypes>) {
  const enableChargebeeRetention = useFlag('enableChargebeeRetention');
  const [openPortal] = useLazyQuery(SELF_SERVE_PORTAL, {
    fetchPolicy: 'no-cache',
  });
  const [checkout] = useLazyQuery(CHECKOUT);
  const [getPrecancelURL] = useLazyQuery(GET_PRECANCEL_URL, {
    fetchPolicy: 'no-cache',
  });
  const [uncancel] = useMutation(UNCANCEL_SUBSCRIPTION);
  const [migratePmpro] = useMutation(MIGRATE_PMPRO);
  const [upgradeId, setUpgradeId] = useState<null | string>(null);
  const [modalInfo, setModalInfo] = useState(UPGRADE_PAYPAL);

  const { showError } = useError();
  const { setLoading } = useApp();
  const { isOpen, closeModal, openModal } = useModal();

  const manageSubscription = async () => {
    try {
      setLoading(true);
      const portalRes = await openPortal();
      setLoading(false);
      window.location.href = JSON.parse(portalRes.data.selfServePortal.session).access_url;
    } catch {
      setLoading(false);
      showError();
    }
  };

  const upgradeSubscription = async (priceId: string) => {
    try {
      setLoading(true);
      const checkoutRes = await checkout({
        variables: { priceId },
      });
      setLoading(false);

      window.location.href = JSON.parse(checkoutRes.data?.checkout?.hostedPage).url;
    } catch {
      setLoading(false);
      showError();
    }
  };

  const upgradePmproSubscription = () => {
    navigateWithReload('/app/join/');
  };

  const cancelSubscription = async () => {
    if (enableChargebeeRetention && data?.platform === EMembershipPlatform.chargebee) {
      try {
        setLoading(true);
        const precancelData = await getPrecancelURL();
        setLoading(false);
        if (!precancelData?.data?.precancel?.url) {
          throw new Error('');
        }
        window.location.href = precancelData?.data?.precancel?.url ?? '';
      } catch {
        setLoading(false);
        showError();
      }
    } else {
      navigateWithReload('/app/cancellation/');
    }
  };

  const migratePmproSubscription = async (
    strategy: EMigrationStrategy,
    chargebeePlanId?: string | null,
    withPortal = true,
  ) => {
    try {
      setLoading(true);
      const migrateRes = await migratePmpro({
        variables: {
          chargebeePlanId: chargebeePlanId ?? null,
          migrationStrategy: strategy,
        },
      });
      setLoading(false);
      if (!withPortal) {
        window.location.reload();
      } else {
        window.location.href =
          migrateRes?.data?.migratePmproSubscriptionToChargebee?.migrationResult?.hostedPageUrl;
      }
    } catch {
      setLoading(false);
      showError();
    }
  };

  const uncancelSubscription = async () => {
    try {
      setLoading(true);
      await uncancel();
      window.location.reload();
    } catch {
      setLoading(false);
      showError();
    }
  };

  const upgradePayPalSubscription = async (chargebeePlanId?: string) => {
    if (!isOpen && chargebeePlanId) {
      setModalInfo(UPGRADE_PAYPAL);
      setUpgradeId(chargebeePlanId);
      openModal();
    }
    if (isOpen && upgradeId) {
      closeModal();
      await migratePmproSubscription(EMigrationStrategy.DURING_UPGRADE, upgradeId);
    }
  };

  const uncancelPayPalSubscription = async () => {
    if (!isOpen) {
      setModalInfo(UNCANCEL_PAYPAL);
      openModal();
    } else {
      closeModal();
      await migratePmproSubscription(EMigrationStrategy.DURING_UNCANCEL);
    }
  };

  const startSubscription = () => {
    navigateWithReload('/members/join/');
  };

  const getLinksWithMethod = () => {
    return getMethods(
      manageSubscription,
      upgradeSubscription,
      upgradePmproSubscription,
      upgradePayPalSubscription,
      uncancelPayPalSubscription,
      cancelSubscription,
      startSubscription,
      uncancelSubscription,
      migratePmproSubscription,
    );
  };

  const getLinksByStatus = () => {
    let links;
    let currentLinks: Array<string> = [];

    if (!data) {
      currentLinks = ['start'];
    } else if (data?.platform === EMembershipPlatform.chargebee) {
      links = getLinksForChargebee(data.billingPeriodUnit, data.billingPeriod, data.group);
    } else if (data?.platform === EMembershipPlatform.pmpro) {
      links = getLinksForPmpro(data.billingPeriodUnit, data.billingPeriod, data.group);
    } else {
      links = getLinksForMobile();
    }

    currentLinks = data && links?.[data.status] ? links[data.status] : currentLinks;

    return (
      <div className="membership-links">
        {currentLinks.map((key: string) => (
          <div key={key} className="link">
            <span className="action-link" onClick={getLinksWithMethod()[key].func}>
              {getLinksWithMethod()[key].linkName}
            </span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <InfoModal
        isOpen={isOpen}
        onOkClick={upgradeId ? upgradePayPalSubscription : uncancelPayPalSubscription}
        title={modalInfo.title}
        info1={modalInfo.info1}
        info2={modalInfo.info2}
      />
      <table>
        <thead>
          <tr>
            <th>LEVEL</th>
            <th>STATUS</th>
            <th>{getDateTitle(data?.status)}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className="table-content">
                {getNameByGroup(data?.group, data?.name, data?.status)}
              </div>
              {getLinksByStatus()}
            </td>
            <td>
              {data?.status && (
                <div className="table-content">
                  <div className={`status-block status-block-${getStatusState(data?.status)}`}>
                    {data?.status}
                  </div>
                </div>
              )}
            </td>
            <td>
              <div className="table-content">
                {data?.group === EMembershipGroup.lifetime
                  ? '-'
                  : formatTimestampDateString(data?.timestamp)}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

import React, { useState, ChangeEvent } from 'react';
import { useMutation } from '@apollo/client';
import { UPLOAD_PROFILE_IMAGE } from '../../../services/ApolloClient';
import { ReactComponent as CameraIcon } from '../../../assets/images/camera.svg';
import defaultAvatar from '../../../assets/images/defaultAvatar.png';

export const ProfilePicture = ({ url = '' }: { url: string }) => {
  const [imageUrl, setImageUrl] = useState(url);
  const [uploadImage] = useMutation(UPLOAD_PROFILE_IMAGE);

  const handleImageUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files?.[0];

    if (!fileObj) return;
    try {
      const response = await uploadImage({
        variables: {
          file: fileObj,
        },
        fetchPolicy: 'no-cache',
      });

      const newImageUrl = response?.data?.updateProfile?.user?.profilePictureUrl || '';
      setImageUrl(newImageUrl);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="profile-picture-wrapper">
      <label htmlFor="profilePicture" className="label-wrapper">
        <img className="profile-picture" src={imageUrl || defaultAvatar} alt="profile_picture" />
        <div className="camera-icon-container">
          <CameraIcon />
        </div>
        <input
          id="profilePicture"
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          style={{ display: 'none' }}
        />
      </label>
    </div>
  );
};

import { getToken } from '../../utils';
import { config } from '../Constants/AppConstants';
import { AuthProvider } from '../../types/auth';

async function executeRequest(method: string, url: string, data?: any) {
  try {
    const requestData: any = {
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: '',
      },
    };

    if (data) {
      requestData.body = JSON.stringify(data);
    }

    const token = getToken();
    if (token) {
      requestData.headers.Authorization = `Bearer ${token}`;
    }

    const response = await fetch(`${config.USER_API_URL}/${url}`, requestData);
    return await response.json();
  } catch (error: any) {
    throw Error(error);
  }
}

export async function getUserHash() {
  const url = 'v1/intercom/user-hash/web';
  return executeRequest('GET', url);
}

export const authRedirect = (provider: AuthProvider) => {
  window.location.href = `${
    config.USER_API_URL
  }/v1/oauth/${provider.toLowerCase()}/web/authorization-url`;
};

export const completePendingAuthentication = async (postData: any) => {
  const url = 'v1/oauth/complete-pending-authentication';
  return executeRequest('POST', url, postData);
};

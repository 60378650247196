import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from '../../components/Modal';
import Button from '../../components/Button/Button';
import { ReactComponent as Checkmark } from '../../assets/images/checkmark.svg';
import './ChangePassword.scss';

interface ChangePasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
  withKey: boolean;
}

export const ChangePasswordModal = ({ isOpen, onClose, withKey }: ChangePasswordModalProps) => {
  const navigate = useNavigate();

  const goNext = () => {
    onClose();
    if (withKey) {
      navigate('/login');
    } else {
      window.location.href = `${window.location.origin}/members/account/`;
    }
  };
  return (
    <Modal isOpen={isOpen}>
      <Checkmark width={65} height={65} />
      {withKey ? (
        <>
          <h3 className="modal-title">Password Changed</h3>
          <p className="modal-text">Please log in with the new password.</p>
        </>
      ) : (
        <p className="modal-text">Your password has been successfully changed.</p>
      )}

      <Button
        label={withKey ? 'Log In' : 'Ok'}
        styleKey="dark"
        className="modal-btn"
        onClick={goNext}
      />
    </Modal>
  );
};

export interface Item {
  text?: string | null;
  subItems?: Array<string>;
  isParagraph?: boolean;
  title?: string;
  isTable?: boolean;
}

export interface Details {
  title: string;
  items: Array<Item>;
}

export const details: Array<Details> = [
  {
    title: 'Details',
    items: [
      {
        text: 'Triple-layered compression',
        subItems: [
          'Lightweight, breathable mesh',
          'Soft and supportive spandex',
          'Comfortable picot elastic',
        ],
      },
      {
        text: '6 rows of hook-and-eye clasps to evolve with your body as it heals',
      },
      {
        text: 'Designed for comfort in all positions, including sitting',
      },
      {
        text: 'Flexible boning to stay in place as you move',
      },
      {
        text: 'Arched design minimizes back bulge',
      },
      {
        text: 'Latex free',
      },
      {
        text: 'Made in the USA',
      },
    ],
  },
  {
    title: 'Benefits',
    items: [
      {
        text: 'Gentle, targeted compression draws abdominal muscles closer and helps with healing.',
      },
      {
        text: 'Breathable materials make it comfortable and perfect for longer wear.',
      },
      {
        text: 'Flexible boning stays in place as you move.',
      },
      {
        text: 'Adjustable support evolves with you as your body heals.',
      },
      {
        text: 'Targeted 360-degree compression promotes healthy posture.',
      },
      {
        text: 'Flattering support is almost invisible under clothing.',
      },
      {
        text: 'Gorgeous material and detailing helps you feel as beautiful and strong as you are, inside and out.',
      },
    ],
  },
  {
    title: 'Who it’s for',
    items: [
      {
        text: 'EMbody Core Support can benefit you if ANY of the following are true:',
        isParagraph: true,
      },
      {
        text: 'You have diastasis recti (abdominal separation).',
      },
      {
        text: 'You wish to accelerate core recovery after birth, core injury or abdominal surgery.',
      },
      {
        text: 'You desire gentle yet effective core support that disappears under clothing.',
      },
      {
        text: 'You are at least 6 weeks postpartum.',
      },
      {
        text: 'You wish to improve your posture.',
      },
    ],
  },
  {
    title: 'Size & fit',
    items: [
      {
        text: 'Please measure yourself to ensure proper fit for your EMbody Core Support garment. Once you have measured, you can find your best size in our chart below.',
        isParagraph: true,
      },
      {
        title: 'Measuring Tips',
      },
      {
        text: 'Stand up straight and exhale.',
      },
      {
        text: 'Wrap a tape measure around your waist, a couple inches above the navel.',
      },
      {
        text: 'Hold the tape measure flat against your body, not too tight, and take a reading.',
      },
      {
        text: 'If measurement is between sizes, size down.',
      },
      {
        text: null,
        isTable: true,
      },
    ],
  },
  {
    title: 'Care & FAQ',
    items: [
      {
        text: 'Delicate wash on cold. Tumble dry low. No bleach. Wash and dry in a lingerie bag for longer wear.',
        isParagraph: true,
      },
      {
        title: 'How does it work?',
        text: 'EMbody Core Support is designed to help approximate (draw together) the rectus abdominis muscles to support healing diastasis recti (abdominal separation) by alleviating mechanical stress on the linea alba, the connective tissue that runs vertically along the midline of the abdomen. Drawing the abdominal muscles closer together makes it easier to recruit the deep core muscles and the pelvic floor, especially when recovering from injury, pregnancy or surgery.',
        isParagraph: true,
      },
      {
        title: 'How soon after birth can I wear EMbody Core Support?',
        text: 'We recommend waiting until you are 6 weeks post-delivery.',
        isParagraph: true,
      },
      {
        title: 'Can I wear this if I’m recovering from a C-section?',
        text: 'Yes, EMbody Core Support provides wonderful support following a Cesarean. Be sure to wait until the incision is healed and you are at least 6 weeks postpartum.',
        isParagraph: true,
      },
      {
        title:
          'I have pelvic organ prolapse, but I also have DR. Is EMbody Core Support right for me?',
        text: 'We recommend that you focus first on healing pelvic organ prolapse with a physical therapist specializing in pelvic floor rehabilitation, and/or Every Mother’s clinically proven app-based therapeutic exercise program. Once you are symptom-free, you are ready to try EMbody Core Support to help draw your abdominal muscles toward the midline to accelerate core recovery.',
        isParagraph: true,
      },
    ],
  },
];

export const imageCaption: { [key: string]: string } = {
  black: 'Model is wearing EMbody Core Support in Black',
  blush: 'Image of EMbody Core Support in Blush',
};

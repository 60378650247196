import React from 'react';
import { ProviderIcon } from './ProviderIcon';
import { ExternalIdentifierData } from '../types';

interface ExternalIdentifierBlockProps {
  providersData: Array<ExternalIdentifierData>;
}

export const ExternalIdentifierBlock = ({ providersData }: ExternalIdentifierBlockProps) => (
  <div className="external-identifiers-block">
    <h4>Linked Accounts:</h4>
    <div>
      {providersData.map(({ externalUserId, provider }: ExternalIdentifierData) => (
        <ProviderIcon key={externalUserId} provider={provider} />
      ))}
      <span>{providersData[0].externalUserEmail}</span>
    </div>
  </div>
);

import React, { ChangeEvent, useRef, useState } from 'react';
import './Dropdown.scss';
import { ReactComponent as ChevronUp } from '../../assets/images/closeListIcon.svg';
import { ReactComponent as ChevronDown } from '../../assets/images/openListIcon.svg';
import useOutsideClick from '../../hooks/useOutsideClick';
import { DropdownItem } from '../../types/forms';

interface IPropTypes {
  id: string;
  options: Array<DropdownItem>;
  value: DropdownItem | null;
  handleChange: (item: DropdownItem) => void;
  placeholder?: string;
  label?: string;
  isSearchable?: boolean;
}

const Dropdown = ({
  id,
  options,
  value,
  handleChange,
  placeholder,
  label,
  isSearchable = false,
}: Readonly<IPropTypes>) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState('');

  const dropdownRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: dropdownRef,
    handler: () => setIsOpen(false),
  });

  const onSelect = (item: DropdownItem) => {
    handleChange(item);
    setSearchValue('');
    setIsOpen(false);
  };

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const getOptions = () => {
    if (!isSearchable) return options;

    return options.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase()));
  };

  return (
    <div ref={dropdownRef} className="dropdown-container">
      <label htmlFor={id}>
        {label && <span className="label">{label}</span>}
        <button
          id={id}
          aria-haspopup="true"
          aria-expanded={isOpen}
          type="button"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className={`${!value ? 'placeholder' : ''}`}>{value?.name ?? placeholder}</span>
          {isOpen ? <ChevronUp /> : <ChevronDown />}
        </button>
      </label>
      {isOpen && (
        <div aria-label="Dropdown menu" className="menu">
          {isSearchable && (
            <div className="search">
              <input
                className="search-control"
                placeholder="Search..."
                onChange={onSearch}
                value={searchValue}
              />
            </div>
          )}
          <ul role="menu" aria-labelledby={id} aria-orientation="vertical">
            {getOptions().map(item => (
              <li
                role="presentation"
                key={item.id}
                onClick={() => onSelect(item)}
                className="option"
              >
                <span>{item.name}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;

import React from 'react';
import { Modal } from '../Modal';
import emptyCart from '../../assets/images/emptyCart.png';

interface EmptyCartModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const EmptyCartModal = ({ isOpen, onClose }: EmptyCartModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="empty-cart-container">
        <img className="empty-cart-img" src={emptyCart} alt="empty_cart_img" />
        <h3 className="empty-cart-disclaimer">Oops! Your cart is empty!</h3>
      </div>
    </Modal>
  );
};

export default EmptyCartModal;

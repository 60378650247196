export const formatDateString = (
  dateString: Date | string,
  options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  },
) => new Date(dateString).toLocaleDateString('en-US', options);

export const formatTimestampDateString = (
  timestamp?: number,
  options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  },
) => {
  if (!timestamp) return '';

  return new Date(timestamp * 1000).toLocaleDateString('en-US', options);
};

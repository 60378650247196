import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { ErrorModal } from '../../components/ErrorModal';
import { GET_MEMBERSHIP, GET_REFERRAL_DATA } from '../../services/ApolloClient';
import { Loader } from '../../components/AppLoader';
import { navigateWithReload } from '../../utils';

enum ReferralStatus {
  EXPIRED = 'LIFETIME_EXCEEDED',
  ACTIVE = 'ACTIVE',
  REDEEMED = 'REFEREES_LIMIT_EXCEEDED',
}

const ERROR_PRIMARY_BUTTON_TEXT = 'Start your 7-day free trial';
const ERROR_SECONDARY_BUTTON_TEXT = 'Learn more about Every Mother';
const ERROR_TITLE = 'Oh no! Your 30-day guest link has expired.';
const ERROR_MESSAGE =
  'Reach out to the person who shared the link, or kick off your journey with a 7-day free trial.';
const ERROR_BUTTON_TEXT = 'Ok';

export function ReferralValidator() {
  const [searchParams] = useSearchParams();
  const referralId = searchParams.get('referral_id');

  if (!referralId) {
    navigateWithReload('/app/your-path/');
  }

  const {
    data: referralData,
    loading: referralDataLoading,
    error: referralDataError,
  } = useQuery(GET_REFERRAL_DATA, {
    skip: !referralId,
    variables: { referralId },
  });
  const {
    data: membershipData,
    loading: membershipDataLoading,
    error: membershipDataError,
  } = useQuery(GET_MEMBERSHIP, {
    fetchPolicy: 'no-cache',
    variables: {
      forceUpdate: true,
    },
  });

  if (referralDataLoading && membershipDataLoading) return <Loader />;

  if (referralDataError || membershipDataError) {
    return (
      <ErrorModal
        buttonText={ERROR_BUTTON_TEXT}
        onSubmit={() => navigateWithReload('/members/join')}
      />
    );
  }

  if (membershipData?.membership) {
    navigateWithReload('/app/your-path/');
    return null;
  }

  if (referralData?.referral?.status === ReferralStatus.ACTIVE) {
    navigateWithReload(`/members/checkout/?referral_id=${referralId}`);
    return null;
  }

  return (
    <ErrorModal
      isIcon={false}
      customClassName="error-modal-referral"
      title={ERROR_TITLE}
      message={ERROR_MESSAGE}
      buttonText={ERROR_PRIMARY_BUTTON_TEXT}
      secondaryButtonText={ERROR_SECONDARY_BUTTON_TEXT}
      onSubmit={() => navigateWithReload('/members/join')}
      onSecondarySubmit={() => navigateWithReload('/#how-it-works')}
    />
  );
}

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  changePasswordFields,
  changePasswordWithKeyFields,
  PASSWORD_MATCH_ERROR_MESSAGE,
} from '../../services/Constants/FormConstants';
import Form from '../../components/Form/Form';
import { EDataKey, TData } from '../../types/forms';
import { CHANGE_PASSWORD, CHANGE_PASSWORD_WITH_KEY } from '../../services/ApolloClient';
import { validateChangePasswordToken } from '../../utils';
import { useApp } from '../../contexts';
import { API } from '../../services/API/BaseAPI';
import { ChangePasswordModal } from './ChangePasswordModal';
import { useModal } from '../../components/Modal';

interface IPropTypes {
  withKey?: boolean;
}

function ChangePassword(props: Readonly<IPropTypes>) {
  const { withKey = false } = props;
  const fields = withKey ? changePasswordWithKeyFields : changePasswordFields;
  const [formError, setFormError] = useState({});
  const [changePasswordWithKey] = useMutation(CHANGE_PASSWORD_WITH_KEY);
  const [changePassword] = useMutation(CHANGE_PASSWORD);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setLoading } = useApp();
  const { isOpen, closeModal, openModal } = useModal();

  useEffect(() => {
    const token: string | null = searchParams.get('token');
    if (withKey && !validateChangePasswordToken(token)) {
      navigate('/forgot-password', { state: { fromChange: true } });
    }
  }, [withKey, searchParams, navigate]);

  const handleChangePassword = async (formData: Partial<TData>) => {
    try {
      setLoading(true);
      setFormError({});
      if (formData.currentPassword && formData.currentPassword === formData.newPassword) {
        setLoading(false);
        setFormError({
          message: 'Your new password can not be the same as your current password',
          fields: [EDataKey.currentPassword, EDataKey.newPassword],
        });
        return;
      }
      if (formData.newPassword !== formData.confirmPassword) {
        setLoading(false);
        setFormError({
          message: PASSWORD_MATCH_ERROR_MESSAGE,
          fields: [EDataKey.newPassword, EDataKey.confirmPassword],
        });
        return;
      }

      if (withKey) {
        await changePasswordWithKey({
          variables: {
            newPassword: formData.newPassword,
            newPasswordConfirm: formData.confirmPassword,
            token: searchParams.get('token'),
          },
        });
        setLoading(false);
        openModal();
      } else {
        await changePassword({
          variables: {
            newPassword: formData.newPassword,
            newPasswordConfirm: formData.confirmPassword,
            currentPassword: formData.currentPassword,
          },
        });
        await API.post('auth-cookie');
        setLoading(false);
        openModal();
      }
    } catch (e: any) {
      setLoading(false);
      setFormError({
        message: e.message,
        fields: [EDataKey.newPassword, EDataKey.confirmPassword, EDataKey.currentPassword],
      });
    }
  };

  return (
    <>
      <div className="register-login-container">
        <Helmet>
          <title>Change Password | Every Mother</title>
        </Helmet>
        <div className="register-login-content">
          <Form
            title="Change Password"
            fields={fields}
            handleForm={formData => handleChangePassword(formData)}
            formError={formError}
            buttonLabel="Change Password"
          />
          {withKey && (
            <div className="form-links">
              <p>Not a Member? &nbsp;</p>
              <a className="navigate-link" href="/members/join/">
                join now
              </a>
            </div>
          )}
        </div>
      </div>
      <ChangePasswordModal isOpen={isOpen} onClose={closeModal} withKey={withKey} />
    </>
  );
}

export default ChangePassword;

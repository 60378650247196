import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import Button from '../../../components/Button/Button';
import { Modal } from '../../../components/Modal';
import './ReasonsModal.scss';
import { CANCEL_SUBSCRIPTION } from '../../../services/ApolloClient';
import { navigateWithReload } from '../../../utils';
import { useApp } from '../../../contexts';

const options = [
  { key: 'achieved-results', name: 'I achieved my results.', value: 'achieved-results' },
  { key: 'no-money', name: 'The cost is too high.', value: 'no-money' },
  { key: 'no-time', name: `I don't have time to exercise.`, value: 'no-time' },
  { key: 'more-accountability', name: 'I need more accountability.', value: 'more-accountability' },
  { key: 'not-for-me', name: `The program wasn't what I expected.`, value: 'not-for-me' },
  { key: 'other', name: 'Other', value: 'other' },
];

const REASON_ERROR_MESSAGE = 'Please select a reason for cancellation';
const OTHER_REASON_ERROR_MESSAGE = 'Please share your reason for cancellation';
const WRONG_ERROR_MESSAGE = 'Something went wrong! Try again!';

interface IReasonsModalPropTypes {
  onBackClick: () => void;
}

function ReasonsModal({ onBackClick }: Readonly<IReasonsModalPropTypes>) {
  const [searchParams] = useSearchParams();
  const [currentValue, setCurrentValue] = useState<string | null>(null);
  const [isMore, setIsMore] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [cancel, { loading }] = useMutation(CANCEL_SUBSCRIPTION);
  const { setLoading } = useApp();

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  const handleInputChange = (event: { target: { value: string; name: string } }) => {
    setError(null);
    const { value, name } = event.target;
    setCurrentValue(value);
    setIsMore(value === 'other' || name === 'details_other');
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    try {
      event.preventDefault();
      if (!currentValue) {
        setError(REASON_ERROR_MESSAGE);
        return;
      }
      if (currentValue === 'other' || !currentValue.length) {
        setError(OTHER_REASON_ERROR_MESSAGE);
        return;
      }
      await cancel({
        variables: {
          cancellationReason: currentValue,
        },
      });
      const fromDelete = searchParams.get('fromDelete');
      if (fromDelete) {
        navigateWithReload('/app/delete-account/');
      } else {
        navigateWithReload('/app/account/');
      }
    } catch (e) {
      setError(WRONG_ERROR_MESSAGE);
    }
  };

  return (
    <Modal isOpen>
      <div className="reasons-container">
        <p className="title">
          We are sorry to see you go! <br /> Please let us know why you are cancelling.
        </p>
        {error && <p className="reason-error-message">{error}</p>}
        <form onSubmit={handleSubmit} className="options-container">
          {options.map(it => (
            <div key={it.key} className="option-item">
              <input
                onChange={handleInputChange}
                type="radio"
                id={it.key}
                name="reason"
                value={it.value}
              />
              <label htmlFor={it.key}>{it.name}</label>
            </div>
          ))}
          {isMore && (
            <div className="option-more">
              <span>Please Specify:</span>
              <textarea onChange={handleInputChange} name="details_other" />
            </div>
          )}
          <div className="buttons">
            <Button styleKey="dark" className="button-item" label="Continue" />
            <span onClick={onBackClick} className="action-link">
              Don&apos;t cancel
            </span>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default ReasonsModal;

import React, { useLayoutEffect, useState } from 'react';
import './Header.scss';
import { useAuth } from '../../contexts';
import { ReactComponent as EMLogo } from '../../assets/images/emLogo.svg';
import { ReactComponent as HamburgerMenu } from '../../assets/images/hamburgerMenu.svg';
import NavDropdown from './NavDropdown/NavDropdown';
import { ILink } from '../../types/nav';

const links = [
  {
    type: 'link',
    name: 'Programs',
    link: '/#programs',
    className: 'jump-to-programs-navigation',
  },
  {
    type: 'link',
    name: 'Evidence',
    link: '/#evidence',
    className: 'jump-to-evidence-navigation',
  },
  {
    type: 'link',
    name: 'How It Works',
    link: '/#how-it-works',
    className: 'jump-to-how-it-work-navigation',
  },
  {
    type: 'link',
    name: 'Medical Advisors',
    link: '/#medicaladvisors',
    className: 'jump-to-how-it-work-navigation',
  },
  {
    type: 'dropdown',
    name: 'PT/OT Partners',
    className: 'open-pt-ot-partners-dropdown',
    options: [
      {
        type: 'link',
        name: 'Find a Pelvic Floor Therapist',
        link: '/find-a-pelvic-floor-therapist',
        className: 'jump-to-find-a-pelvic-floor-therapist-navigation',
      },
      {
        type: 'link',
        name: 'Partner With Us',
        link: '/ptpartners',
        className: 'jump-to-ptpartners-navigation',
      },
    ],
  },
];

function Header() {
  const [menuClass, setMenuClass] = useState('');
  const { signed, logout } = useAuth();

  const toggleMenu = () => {
    if (menuClass === '') {
      setMenuClass('m-show');
    } else {
      setMenuClass('m-remove');
      setTimeout(() => {
        setMenuClass('');
      }, 500);
    }
  };

  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth > 1024) {
        setMenuClass('');
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [menuClass]);

  const handleLogoutClick = () => {
    logout();
  };

  const getLink = (item: ILink) => {
    if (item.type === 'dropdown') {
      return (
        <NavDropdown
          id="nav-dropdown"
          key="nav-dropdown"
          label={item.name}
          options={item.options}
        />
      );
    }
    return (
      <li className="e-link-li" key={item.link}>
        <a className={`e-link ${item.className}`} href={item.link}>
          {item.name}
        </a>
      </li>
    );
  };

  return (
    <header className="e-public-header">
      <nav className="b-public-nav">
        <div className="e-hamburger-wrap" data-testid="hamburger-button" onClick={toggleMenu}>
          <HamburgerMenu className="e-hamburger" />
        </div>
        <div className="e-logo-title">
          <a className="e-logo-link hp-footer-cta" href="/">
            <EMLogo className="e-logo hp-navigation-cta" />
          </a>
        </div>
        <div className={`e-link-wrap ${menuClass}`} data-testid="menu">
          <ul className="e-link-list">{links.map(it => getLink(it))}</ul>
          <ul className="e-account-links">
            <li className="e-account-item m-first">
              {signed ? (
                <a className="e-account-link" href="/app/your-path/">
                  Go To Program
                </a>
              ) : (
                <a className="e-account-link initiate-signup-navigation-cta" href="/members/join">
                  Get Started
                </a>
              )}
            </li>
            <li className="e-account-item m-second">
              {signed ? (
                <span className="e-account-link m-second" onClick={handleLogoutClick}>
                  Logout
                </span>
              ) : (
                <a className="e-account-link m-second" href="/login">
                  Log In
                </a>
              )}
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Header;

import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { validateUrl } from '../../utils';

function Home() {
  const [searchParams] = useSearchParams();
  const redirectTo = validateUrl(searchParams.get('redirect_to'));

  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    } else {
      window.location.href = `${window.location.origin}/app/your-path/`;
    }
  }, [redirectTo]);

  return <div className="home" />;
}

export default Home;

import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  navigateWithReload,
  removeFields,
  getLocalStorageItem,
  getImpactFields,
  cleanLocalStorageItems,
  navigateToExternalRoute,
  capitalizeFirstLetter,
} from '../../utils';
import { API } from '../../services/API/BaseAPI';
import { useApp, useAuth, useProducts } from '../../contexts';
import { ErrorModal } from '../../components/ErrorModal';
import { OAuthCallbackError } from '../../types/auth';
import { FINALIZE_REGISTRATION } from '../../services/ApolloClient';
import { ErrorsService } from '../../services/ErrorsService';
import LinkSocialModal from '../../components/LinkSocialModal/LinkSocialModal';

const ERROR_PRIMARY_BUTTON_TEXT = 'Sign In with email';
const ERROR_SECONDARY_BUTTON_TEXT = 'Create a new account';
const EMAIL_ALREADY_EXISTS_ERROR_MESSAGE =
  'Your email is already registered in the system. Please sign in with your email and password.';
const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please sign in with your email and password.';

const getErrorMessage = (error: string) => {
  if (error === OAuthCallbackError.EMAIL_ALREADY_EXISTS) return EMAIL_ALREADY_EXISTS_ERROR_MESSAGE;

  return DEFAULT_ERROR_MESSAGE;
};

export function OAuth() {
  const [searchParams] = useSearchParams();
  const [finalizeRegistration] = useMutation(FINALIZE_REGISTRATION);
  const { login } = useAuth();
  const { setLoading } = useApp();
  const [authToken, setAuthToken] = useState(searchParams.get('token'));
  const isNewUser = Boolean(Number(searchParams.get('is_new_user')));
  const searchParamsError = searchParams.get('error');
  const email = searchParams.get('email');
  const authenticationId = searchParams.get('pending_authentication_id');
  const provider = searchParams.get('provider');
  const [error, setError] = useState(searchParamsError);
  const { getCheckoutUrl } = useProducts();

  const validateMetroPtRegistration = useCallback(async (): Promise<boolean> => {
    const impactFields = getImpactFields();
    const res = await finalizeRegistration({
      variables: {
        impact: impactFields,
      },
    });
    return res.data?.finalizeRegistration?.isMetroPt;
  }, [finalizeRegistration]);

  const getRedirectUrl = useCallback(async (): Promise<string> => {
    let url = '/app/your-path/';
    const isProductRedirect = getLocalStorageItem('is_product_redirect');

    if (isNewUser) {
      const isMetroPt = await validateMetroPtRegistration();
      removeFields();
      if (isProductRedirect) {
        url = '/products/checkout/?fromProduct=true';
      }

      const referralId = getLocalStorageItem('referral_id');
      if (referralId) {
        url = `/members/checkout/?referral_id=${referralId}`;
      }

      const paymentPlan = getLocalStorageItem('payment_plan');
      if (paymentPlan) {
        const checkoutPath = isMetroPt ? 'metro-pt' : 'checkout';
        url = `/members/${checkoutPath}/?level=${paymentPlan}&isNew=true`;
      }
    } else if (isProductRedirect) {
      url = await getCheckoutUrl();
    }
    cleanLocalStorageItems(['referral_id', 'is_product_redirect']);

    return url;
  }, [isNewUser, validateMetroPtRegistration, getCheckoutUrl]);

  const navigateAfterLogin = useCallback(async () => {
    try {
      setLoading(true);
      if (authToken) {
        const redirectUrl = await getRedirectUrl();
        login(authToken);
        await API.post('auth-cookie');
        if (redirectUrl.startsWith('/')) {
          navigateWithReload(redirectUrl);
        } else {
          navigateToExternalRoute(redirectUrl);
        }
      } else {
        ErrorsService.message('[OAuth]: Failed to receive valid authentication token');
        throw Error();
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      setError(searchParamsError ?? 'unknown');
    }
  }, [getRedirectUrl, login, searchParamsError, setLoading, authToken]);

  const onLinkSocial = async (token: string) => {
    setAuthToken(token);
  };

  useEffect(() => {
    navigateAfterLogin();
  }, [navigateAfterLogin]);

  if (error) {
    if (error === 'password_authentication_required' && authenticationId && email && provider) {
      return (
        <LinkSocialModal
          authenticationId={authenticationId}
          onSuccess={onLinkSocial}
          email={email}
          provider={capitalizeFirstLetter(provider)}
        />
      );
    }
    return (
      <ErrorModal
        isIcon={false}
        customClassName="error-modal-oauth"
        message={getErrorMessage(error)}
        buttonText={ERROR_PRIMARY_BUTTON_TEXT}
        secondaryButtonText={ERROR_SECONDARY_BUTTON_TEXT}
        onSubmit={() => navigateWithReload('/login/')}
        onSecondarySubmit={() => navigateWithReload('/members/create-account/')}
      />
    );
  }

  return <div />;
}

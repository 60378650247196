import { EMembershipGroup, EMembershipStatus, EMigrationStrategy } from '../types';

export const getDateTitle = (status?: EMembershipStatus) => {
  switch (status) {
    case EMembershipStatus.trial:
      return 'first payment date';
    case EMembershipStatus.active:
      return 'renewal date';
    case EMembershipStatus.expired:
    case EMembershipStatus.cancelled:
      return 'expiration date';
    default:
      return 'expiration date';
  }
};

export const getStatusState = (status?: EMembershipStatus) => {
  switch (status) {
    case EMembershipStatus.trial:
    case EMembershipStatus.active:
      return 'active';
    case EMembershipStatus.expired:
    case EMembershipStatus.cancelled:
      return 'inactive';
    default:
      return 'active';
  }
};

export const getMethods = (
  manageSubscription: () => Promise<void>,
  upgradeCBSubscription: (id: string) => Promise<void>,
  upgradePmproSubscription: () => void,
  upgradePayPalSubscription: (chargebeePlanId?: string) => Promise<void>,
  uncancelPayPalSubscription: () => Promise<void>,
  cancel: () => Promise<void>,
  startSubscription: () => void,
  uncancelSubscription: () => Promise<void>,
  migratePmpro: (
    strategy: EMigrationStrategy,
    chargebeePlanId?: string | null,
    withPortal?: boolean,
  ) => Promise<void>,
) => {
  const methods: { [key: string]: { func: () => void | Promise<void>; linkName: string } } = {
    start: {
      func: () => startSubscription(),
      linkName: 'Choose a membership level',
    },
    manage: {
      func: () => manageSubscription(),
      linkName: 'manage',
    },
    monthly: {
      func: () => upgradeCBSubscription('Member-Monthly-1month'),
      linkName: 'upgrade to monthly',
    },
    quarterly: {
      func: () => upgradeCBSubscription('Member-Quarterly-3months'),
      linkName: 'upgrade to quarterly',
    },
    annual: {
      func: () => upgradeCBSubscription('Member-Annual-Yearly'),
      linkName: 'upgrade to annual',
    },
    cancel: {
      func: () => cancel(),
      linkName: 'cancel',
    },
    uncancel: {
      func: () => uncancelSubscription(),
      linkName: 'uncancel',
    },
    upgrade: {
      func: () => upgradePmproSubscription(),
      linkName: 'upgrade plan',
    },
    uncancelBraintree: {
      func: () => migratePmpro(EMigrationStrategy.DURING_UNCANCEL, null, false),
      linkName: 'uncancel',
    },
    quarterlyBraintree: {
      func: () => migratePmpro(EMigrationStrategy.DURING_UPGRADE, 'Member-Quarterly-3months'),
      linkName: 'upgrade to quarterly',
    },
    annualBraintree: {
      func: () => migratePmpro(EMigrationStrategy.DURING_UPGRADE, 'Member-Annual-Yearly'),
      linkName: 'upgrade to annual',
    },
    quarterlyPayPal: {
      func: () => upgradePayPalSubscription('Member-Quarterly-3months'),
      linkName: 'upgrade to quarterly',
    },
    annualPayPal: {
      func: () => upgradePayPalSubscription('Member-Annual-Yearly'),
      linkName: 'upgrade to annual',
    },
    uncancelPayPal: {
      func: () => uncancelPayPalSubscription(),
      linkName: 'uncancel',
    },
  };

  return methods;
};

export const getLinksForChargebee = (
  billingPeriodUnit: string,
  billingPeriod: number,
  group: EMembershipGroup,
) => {
  const consumerActiveStatusLinks: Record<string, Array<string>> = {
    day: ['monthly', 'quarterly', 'annual', 'manage', 'cancel'],
    month1: ['quarterly', 'annual', 'manage', 'cancel'],
    month3: ['annual', 'manage', 'cancel'],
    year: ['manage', 'cancel'],
  };

  const consumerLinks: Record<string, Array<string>> = {
    [EMembershipStatus.trial]: ['manage', 'cancel'],
    [EMembershipStatus.active]:
      consumerActiveStatusLinks[
        `${billingPeriodUnit}${billingPeriodUnit === 'month' ? billingPeriod : ''}`
      ],
    [EMembershipStatus.expired]: ['start'],
    [EMembershipStatus.cancelled]: ['manage', 'uncancel'],
  };

  const enterpriseLinks: Record<string, Array<string>> = {
    [EMembershipStatus.expired]: ['start'],
  };

  return group === EMembershipGroup.consumer ? consumerLinks : enterpriseLinks;
};

export const getLinksForPmpro = (
  billingPeriodUnit: string,
  billingPeriod: number,
  group: EMembershipGroup,
) => {
  const consumerActiveStatusLinks: Record<string, Array<string>> = {
    month: ['upgrade', 'cancel'],
    year: ['cancel'],
  };

  const consumerLinks: Record<string, Array<string>> = {
    [EMembershipStatus.trial]: ['cancel'],
    [EMembershipStatus.active]: consumerActiveStatusLinks[billingPeriodUnit],
    [EMembershipStatus.expired]: ['start'],
  };

  const migrationBraintreeActiveStatusLinks: Record<string, Array<string>> = {
    month1: ['quarterlyBraintree', 'annualBraintree', 'cancel'],
    month3: ['annualBraintree', 'cancel'],
    year: ['cancel'],
  };

  const migrationBraintreeLinks: Record<string, Array<string>> = {
    ...consumerLinks,
    [EMembershipStatus.active]:
      migrationBraintreeActiveStatusLinks[
        `${billingPeriodUnit}${billingPeriodUnit === 'month' ? billingPeriod : ''}`
      ],
    [EMembershipStatus.cancelled]: ['uncancelBraintree'],
  };

  const migrationPayPalActiveStatusLinks: Record<string, Array<string>> = {
    month1: ['quarterlyPayPal', 'annualPayPal', 'cancel'],
    month3: ['annualPayPal', 'cancel'],
    year: ['cancel'],
  };

  const migrationPayPalLinks: Record<string, Array<string>> = {
    ...consumerLinks,
    [EMembershipStatus.active]:
      migrationPayPalActiveStatusLinks[
        `${billingPeriodUnit}${billingPeriodUnit === 'month' ? billingPeriod : ''}`
      ],
    [EMembershipStatus.cancelled]: ['uncancelPayPal'],
  };

  if (group === EMembershipGroup.consumer) {
    return consumerLinks;
  }
  if ([EMembershipGroup.migration_braintree, EMembershipGroup.migration_free].includes(group)) {
    return migrationBraintreeLinks;
  }

  if (group === EMembershipGroup.migration_paypal) {
    return migrationPayPalLinks;
  }
  return { [EMembershipStatus.expired]: ['start'] };
};

export const getLinksForMobile = () => {
  const links: Record<string, Array<string>> = {
    [EMembershipStatus.expired]: ['start'],
  };

  return links;
};

export const getNameByGroup = (
  group?: EMembershipGroup,
  name?: string,
  status?: EMembershipStatus,
) => {
  if (!name || status === EMembershipStatus.expired) {
    return 'You do not have an active membership';
  }

  if (group === EMembershipGroup.iap) {
    return `${name} IOS In-App Subscription`;
  }
  if (group === EMembershipGroup.iab) {
    return `${name} Android In-App Subscription`;
  }

  return name;
};

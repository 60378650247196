import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import cookie from 'react-cookies';
import { decodeToken, navigateWithReload } from '../../utils';
import { API } from '../../services/API/BaseAPI';
import { useApp, useAuth } from '../../contexts';

export function AutoAuth() {
  const [searchParams] = useSearchParams();
  const { login } = useAuth();
  const { setLoading } = useApp();
  const token = searchParams.get('token');

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const tokenData = decodeToken(token);
        if (token && tokenData?.redirect_url) {
          cookie.save('auto-auth', new Date().getTime(), {
            path: '/',
            expires: new Date(Date.now() + 24 * 14 * 3600 * 1000),
            secure: false,
            httpOnly: false,
          });
          localStorage.setItem('auto-auth', String(new Date().getTime()));
          login(token);
          await API.post('auth-cookie');
          navigateWithReload(`${tokenData?.redirect_url}?token=${token}`);
        } else {
          navigateWithReload('/login/');
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        navigateWithReload('/login/');
      }
    })();
  }, [login, token, setLoading]);

  return <div />;
}

import React, { ReactNode, useEffect, useState } from 'react';
import InputField from '../InputField/InputField';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Button from '../Button/Button';
import { IField, TData } from '../../types/forms';

interface IFormPropTypes {
  fields: Array<IField>;
  handleForm: (data: Partial<TData>) => void;
  formError?: any;
  buttonLabel: string;
  title?: string;
  children?: ReactNode;
  formData?: Partial<TData>;
  withLabel?: boolean;
}

export default function Form({
  fields,
  handleForm,
  formError,
  buttonLabel,
  title,
  children,
  formData,
  withLabel = true,
}: Readonly<IFormPropTypes>) {
  const [data, setData] = useState<Partial<TData>>(formData ?? {});
  const [fieldsErrors, setFieldsErrors]: any = useState({});
  const [error, setError] = useState('');

  const handleChange = (event: { target: { name: string; value: string } }) => {
    const { name, value } = event.target;
    setData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const submitFrom = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setFieldsErrors({});
    setError('');
    let isError = false;
    const errors: any = {};
    fields.forEach(field => {
      if (!data?.[field.key]) {
        isError = !isError ? true : isError;
        errors[field.key] = field.error;
      }
    });
    setFieldsErrors(errors);
    if (!isError && data) handleForm(data);
  };

  useEffect(() => {
    if (formError?.fields) {
      const errors: any = {};
      formError?.fields.forEach((key: string) => {
        errors[key] = '';
      });
      setFieldsErrors(errors);
      setError(formError.message);
    }
  }, [formError]);

  return (
    <>
      {title && <h2 className="form-header">{title}</h2>}
      <form onSubmit={submitFrom} className="form">
        {fields.map(field => (
          <InputField
            key={field.key}
            id={field.key}
            type={field.type}
            label={withLabel ? field.label : ''}
            placeholder={field.placeholder}
            handleChange={handleChange}
            value={data?.[field.key] ?? ''}
            error={fieldsErrors[field.key]}
            disabled={field?.isDisabled}
          />
        ))}
        <div className="error-block">
          <ErrorMessage errorMessage={error} />
        </div>
        {children}
        <Button styleKey="dark" label={buttonLabel} className="form-btn" />
      </form>
    </>
  );
}

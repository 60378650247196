import * as Sentry from '@sentry/react';
import type { CaptureContext } from '@sentry/core';

export { Sentry };

export const ErrorsService = {
  message: (message = '', captureContext: Partial<CaptureContext> = {}) => {
    return Sentry.captureMessage(message, {
      level: 'error',
      ...captureContext,
    });
  },
};

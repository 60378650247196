import { useEffect, useState } from 'react';

export const useBanner = () => {
  const [isPromo, setIsPromo] = useState(false);
  const firstDay = 1714536000;
  const lastDay = 1717250400;

  useEffect(() => {
    const currentTime = Math.floor(new Date().getTime() / 1000);

    if (firstDay <= currentTime && currentTime < lastDay) {
      setIsPromo(true);
    } else {
      setIsPromo(false);
    }
  }, []);

  return {
    isPromo,
  };
};

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useProducts } from '../../contexts';
import { Order } from '../../components/Order';
import { ErrorModal } from '../../components/ErrorModal';
import Spinner from '../../components/Spinner/Spinner';
import { Order as OrderData } from '../../types/products';
import { CHARGEBEE_INVOICE } from '../../services/ApolloClient';
import { navigateWithReload } from '../../utils';

const TITLE = 'Congratulations! Your order is complete.';
const DESCRIPTION =
  'We are getting started on your order right away and you will receive a confirmation email shortly';
const PRODUCT_PAGE_URL = '/products/embody-core-support/';

const ConfirmationPage = () => {
  const { cleanCart } = useProducts();
  const [searchParams] = useSearchParams();
  const hostedPageId = searchParams.get('id');
  const { data: invoiceData, loading } = useQuery(CHARGEBEE_INVOICE, {
    variables: {
      hostedPageId,
    },
  });

  const [orderData, setOrderData] = useState<OrderData | null>(null);
  const [description, setDescription] = useState(DESCRIPTION);

  useEffect(() => {
    cleanCart();
    if (!hostedPageId) {
      navigateWithReload(PRODUCT_PAGE_URL);
    }
  }, [cleanCart, hostedPageId]);

  useEffect(() => {
    const currentDate: Date = new Date();

    if (invoiceData) {
      const email: string = invoiceData?.chargebeeInvoice?.shippingAddressEmail ?? '';
      setDescription(DESCRIPTION + (email ? ` to ${email}.` : '.'));

      setOrderData({
        createdAt: currentDate.toString(),
        status: 'Processing',
        discount: invoiceData?.chargebeeInvoice?.totalDiscountAmount,
        total: invoiceData?.chargebeeInvoice?.totalAmount,
        subTotal: invoiceData?.chargebeeInvoice?.totalCostItems,
        products: invoiceData?.chargebeeInvoice?.products,
      });
    }
  }, [loading, invoiceData]);

  if (loading) return <Spinner />;
  if (!loading && !orderData)
    return <ErrorModal onSubmit={() => navigateWithReload(PRODUCT_PAGE_URL)} />;

  return (
    <>
      <Helmet>
        <title>Order confirmation | Every Mother</title>
      </Helmet>
      <Order title={TITLE} description={description} orderData={orderData} />
    </>
  );
};

export default ConfirmationPage;

import { QuestionAnsweredEvent, ReferFriendInitiatedEvent, WebViewEvent } from './models';
import { EVENT, sanitizeObject } from './utils';

const track = (eventName: string, eventData: object, traits: object = {}) => {
  if (window.analytics) {
    window.analytics.track(eventName, sanitizeObject(eventData), {
      traits: sanitizeObject(traits),
    });
  }
};

const screenTrack = (eventData: object) => {
  if (window.analytics) {
    window.analytics.page(sanitizeObject(eventData));
  }
};

export const trackQuestionAnswered = (event: QuestionAnsweredEvent, traits: object = {}) => {
  track(EVENT.QUESTION_ANSWERED, event, traits);
};

export const trackReferFriendInitiatedEvent = (event: ReferFriendInitiatedEvent) => {
  track(EVENT.REFER_FRIEND_INITIATED, event);
};

export const trackWebViewedEvent = (event: WebViewEvent) => {
  screenTrack(event);
};

import { getToken } from '../../utils';
import { config } from '../Constants/AppConstants';

async function executeRequest(method: string, pathname: string, data: any, options: any = {}) {
  try {
    const requestOptions: any = {
      credentials: 'include' as RequestCredentials,
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: data,
    };
    const token = getToken();
    if (token) {
      requestOptions.headers.Authorization = `Bearer ${token}`;
    }
    return await fetch(`${config.WORDPRESS_URL}/${pathname}`, requestOptions);
  } catch (error: any) {
    throw Error(error);
  }
}

export const API = {
  post(pathname: string, data?: any, options?: any) {
    return executeRequest('post', pathname, data, options);
  },
  get(pathname: string, options?: any) {
    return executeRequest('get', pathname, null, options);
  },
};

import React from 'react';

import './Button.scss';

interface IButtonPropTypes {
  label: string;
  styleKey: 'light' | 'dark' | 'form';
  width?: string | number;
  height?: string | number;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  isLoading?: boolean;
}
function Button({
  label,
  styleKey,
  width,
  height,
  onClick,
  disabled = false,
  className,
  isLoading = false,
}: Readonly<IButtonPropTypes>) {
  return (
    <button
      style={{ width: width ?? '', height: height ?? '' }}
      type="submit"
      value={label}
      onClick={onClick}
      className={`button ${styleKey}-button ${className}`}
      disabled={disabled}
    >
      {isLoading ? <span className="spinner" /> : label}
    </button>
  );
}

export default Button;

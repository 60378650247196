import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import './DeleteAccount.scss';
import { Helmet } from 'react-helmet-async';
import { navigateWithReload } from '../../utils';
import ConfirmationBlock from '../../components/ConfirmationBlock';
import { GET_MEMBERSHIP, REMOVE_PROFILE } from '../../services/ApolloClient';
import { EMembershipGroup, EMembershipStatus } from '../MyAccount/components/types';
import { useApp, useError } from '../../contexts';

const WITH_SUB_INFO_MESSAGE =
  'If you delete your account, you will lose access to your account. You will need to cancel your subscription before deleting your account.';

const WITH_SUB_WARNING_MESSAGE =
  'Are you sure you want to continue with the cancellation and delete account?';

const WITHOUT_SUB_INFO_MESSAGE =
  'Deleting your account cannot be undone. If you delete your account, you will lose access to your account immediately. Any in-progress data and personally identifiable information will be deleted. We do not offer refunds. Once deleted, you will need to create a new account and subscription to access Every Mother as a new member. If you would like to cancel and continue with your membership until the end of your membership term, do not delete your account.';

const WITHOUT_SUB_WARNING_MESSAGE = 'Are you sure you want to DELETE your account?';

function DeleteAccount() {
  const { data: membershipData, loading: membershipDataLoading } = useQuery(GET_MEMBERSHIP, {
    fetchPolicy: 'no-cache',
    variables: {
      forceUpdate: true,
    },
  });
  const [remove, { loading }] = useMutation(REMOVE_PROFILE);
  const [isSubscription, setIsSubscription] = useState(false);

  const { showError } = useError();
  const { setLoading } = useApp();

  useEffect(() => {
    setLoading(membershipDataLoading || loading);
  }, [membershipDataLoading, loading, setLoading]);

  useEffect(() => {
    if (
      membershipData?.membership &&
      ![EMembershipStatus.expired, EMembershipStatus.cancelled].includes(
        membershipData.membership.status,
      ) &&
      membershipData.membership.group !== EMembershipGroup.enterprise
    ) {
      setIsSubscription(true);
    }
  }, [membershipData]);

  const navigateToAccount = () => {
    navigateWithReload('/app/account/');
  };

  const navigateToCancellation = () => {
    navigateWithReload('/app/cancellation/?fromDelete=true');
  };

  const removeAccount = async () => {
    try {
      await remove();
      navigateWithReload('/');
    } catch (e) {
      showError();
    }
  };

  return (
    <div className="delete-container">
      <Helmet>
        <title>Delete Account | Every Mother</title>
      </Helmet>
      {!membershipDataLoading &&
        (isSubscription ? (
          <ConfirmationBlock
            info={WITH_SUB_INFO_MESSAGE}
            warning={WITH_SUB_WARNING_MESSAGE}
            continueButtonText="Continue"
            continueButtonClick={navigateToCancellation}
            backButtonText="No"
            backButtonClick={navigateToAccount}
          />
        ) : (
          <ConfirmationBlock
            info={WITHOUT_SUB_INFO_MESSAGE}
            warning={WITHOUT_SUB_WARNING_MESSAGE}
            continueButtonText="Yes, delete this account"
            continueButtonClick={removeAccount}
            backButtonText="No, keep this account"
            backButtonClick={navigateToAccount}
            withImage
          />
        ))}
    </div>
  );
}

export default DeleteAccount;

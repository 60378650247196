import React from 'react';
import { TrackingInfo as TrackingInfoData } from '../../types/products';

export const TrackingInfo = ({ data }: { data: TrackingInfoData | null }) => {
  if (!data) return null;

  return (
    <div className="tracking-info-container">
      <h3 className="tracking-info-header">Tracking Information</h3>
      <div className="row">
        <span>Tracking Number: </span>
        <span>{data?.trackingNumber}</span>
      </div>
      <div className="row">
        <span>Carrier: </span>
        <span>{data?.carrier}</span>
      </div>
      {data?.carrierService ? (
        <div className="row">
          <span>Carrier Service: </span>
          <span>{data.carrierService}</span>
        </div>
      ) : null}
      {data?.trackingUrl ? (
        <div className="row">
          <span>Track: </span>
          <a target="_blank" href={data.trackingUrl} className="link" rel="noreferrer">
            {data.trackingUrl}
          </a>
        </div>
      ) : null}
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from '../Modal';
import './LinkSocialModal.scss';
import Form from '../Form/Form';
import { passwordConfirmationFields } from '../../services/Constants/FormConstants';
import { EDataKey, TData } from '../../types/forms';
import { completePendingAuthentication } from '../../services/API/UserAPI';
import { trackWebViewedEvent } from '../../services/DataPipeline/DataPipeline';

const ERROR = 'Your password is invalid. Please note that the password field is case-sensitive.';

interface IModalTypes {
  authenticationId: string;
  onSuccess: (token: string) => void;
  provider: string;
  email: string;
}

const LinkSocialModal = ({ authenticationId, onSuccess, provider, email }: IModalTypes) => {
  const [formError, setFormError] = useState({});
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    trackWebViewedEvent({ modal_viewed: 'LinkAccountConfirmation' });
  }, []);

  const handleForm = async (formData: Partial<TData>) => {
    try {
      const res = await completePendingAuthentication({
        pending_authentication_id: authenticationId,
        password: formData.password,
      });

      if (res.token) {
        setIsVisible(false);
        onSuccess(res.token);
      } else {
        setFormError({ message: ERROR, fields: [EDataKey.password] });
      }
    } catch (e) {
      setFormError({ message: ERROR, fields: [EDataKey.password] });
    }
  };

  return (
    <Modal isOpen={isVisible}>
      <div className="social-modal-container">
        <h3 className="social-modal-title">
          An Every Mother account with this email already exists
        </h3>
        <span>{email}</span>
        <span className="social-modal-info">
          To enable Sign In with {provider}, please enter your Every Mother password below.
        </span>
        <Form
          fields={passwordConfirmationFields}
          handleForm={handleForm}
          formError={formError}
          withLabel={false}
          buttonLabel="Link your accounts"
        >
          <Link className="navigate-link forgot-password-link" to="/forgot-password">
            forgot password?
          </Link>
          <span className="social-modal-note">
            Note: Once you enable Sign In with {provider}, you will no longer be able to edit your
            email.
          </span>
        </Form>
        <Link className="navigate-link" to="/login">
          Cancel
        </Link>
      </div>
    </Modal>
  );
};

export default LinkSocialModal;

import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Home from '../pages/Home';
import Checkout from '../pages/Checkout';
import SuccessCheckout from '../pages/SuccessCheckout';
import ChangePassword from '../pages/ChangePassword';
import Corset from '../pages/Products/Corset';
import Cart from '../pages/Cart';
import ConfirmationPage from '../pages/Products/ConfirmationPage';
import OrderStatusPage from '../pages/Products/OrderStatusPage';
import MyAccount from '../pages/MyAccount';
import EditProfile from '../pages/EditProfile';
import Cancellation from '../pages/Cancellation';
import DeleteAccount from '../pages/DeleteAccount';
import MetroPT from '../pages/MetroPT';
import { AutoAuth } from '../pages/AutoAuth';
import { Referrals, ReferralValidator } from '../pages/Referrals';

const AuthRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/members/metro-pt/" element={<MetroPT />} />
      <Route path="/members/checkout" element={<Checkout />} />
      <Route path="/members/checkout/success" element={<SuccessCheckout />} />
      <Route path="/members/change-password" element={<ChangePassword />} />
      <Route path="/app/change-password" element={<ChangePassword />} />
      <Route path="/app/edit-profile/" element={<EditProfile />} />
      <Route path="/products/embody-core-support/" element={<Corset />} />
      <Route path="/products/confirmation/" element={<ConfirmationPage />} />
      <Route path="/products/order-status/" element={<OrderStatusPage />} />
      <Route path="/products/checkout/" element={<Checkout />} />
      <Route path="/cart/" element={<Cart />} />
      <Route path="/app/account/" element={<MyAccount />} />
      <Route path="/app/cancellation/" element={<Cancellation />} />
      <Route path="/app/delete-account/" element={<DeleteAccount />} />
      <Route path="/authenticate-by-link/" element={<AutoAuth />} />
      <Route path="/referrals/" element={<Referrals />} />
      <Route path="/members/create-account/" element={<ReferralValidator />} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
};

export default AuthRoutes;

import React from 'react';
import { useAuth } from '../contexts/Auth';

import AuthRoutes from './AuthRoutes';
import PreAuthRoutes from './PreAuthRoutes';

const RoutesList: React.FC = () => {
  const { signed } = useAuth();

  return signed ? <AuthRoutes /> : <PreAuthRoutes />;
};

export default RoutesList;

import React from 'react';
import { Modal } from '../Modal';
import Button from '../Button/Button';
import './InfoModal.scss';

interface IErrorModalTypes {
  onOkClick?: () => void;
  isOpen?: boolean;
  title?: string;
  info1?: string;
  info2?: string;
}

export const InfoModal = ({ onOkClick, isOpen = true, title, info1, info2 }: IErrorModalTypes) => {
  return (
    <Modal isOpen={isOpen}>
      <div className="info-modal-container">
        <h3 className="info-modal-title">{title}</h3>
        <div className="info-description">
          <p>{info1}</p>
          <p>{info2}</p>
        </div>
        <Button label="Ok" styleKey="dark" width="250px" onClick={onOkClick} />
      </div>
    </Modal>
  );
};

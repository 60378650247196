import { ApolloClient, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { deviceDetect } from 'react-device-detect';
import cookie from 'react-cookies';
import { getToken } from '../../utils';
import { config } from '../Constants/AppConstants';
import { getBrowserPlatform, getBrowserVersion } from '../../utils/getBrowser';
import { ErrorsService } from '../ErrorsService';

const uploadLink = createUploadLink({
  uri: config.API_URL,
  credentials: 'include',
});

const authLink = setContext(async (_, { headers }) => {
  const token = getToken();
  const deviceInfo = deviceDetect(navigator.userAgent);
  let deviceInfoData = {};
  if (deviceInfo.vendor && deviceInfo.model) {
    deviceInfoData = {
      'em-system-version': deviceInfo.osVersion,
      'em-device-manufacturer': deviceInfo.vendor,
      'em-device-model': deviceInfo.model,
    };
  }

  const fbp = cookie.load('_fbp');
  const fbc = cookie.load('_fbc');
  let facebookData = {};
  if (fbp) {
    facebookData = { 'em-facebook-fbp': fbp };
  }
  if (fbc) {
    facebookData = { ...facebookData, 'em-facebook-fbc': fbc };
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'em-platform': getBrowserPlatform(),
      'em-browser': getBrowserVersion(),
      'em-channel': 'browser',
      'em-device-type': 'Web',
      ...deviceInfoData,
      ...facebookData,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, path }) => {
      const emLoggedIn = cookie.load('em_logged_in');
      const tokenCookie = cookie.load('em-client-jwt');
      const autoAuth = cookie.load('auto-auth');
      const autoAuthStorage = localStorage.getItem('auto-auth');
      const autoAuthStorageWind = window.localStorage.getItem('auto-auth');
      let additionalData: any = {};
      if (message === 'Not authenticated') {
        additionalData = {
          em_logged_in: emLoggedIn || '0',
          token_cookie: tokenCookie || 'no tokenCookie',
          auto_auth_time_cookie: autoAuth || 'no time',
          auto_auth_time_storage: autoAuthStorage || 'no time',
          auto_auth_time_storage_window: autoAuthStorageWind || 'no time',
        };
        const data = localStorage.getItem('em-client-jwt');
        if (data) {
          const { token } = JSON.parse(data);
          additionalData = { ...additionalData, token: token || 'no token' };
        }
      }

      return ErrorsService.message(`[ApolloError ${path?.[0] ?? 'UnknownQuery'}]: ${message}`, {
        extra: { ...additionalData },
      });
    });

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(uploadLink)),
  cache: new InMemoryCache(),
});

export default client;

export interface OrderProduct {
  name: string;
  color: string;
  size: string;
  image: string;
  price: string;
  quantity: number;
}

export interface TrackingInfo {
  carrier: string;
  trackingNumber: string;
  carrierService: string | null;
  trackingUrl: string | null;
}

export interface Order {
  id?: string;
  createdAt: string;
  status: string;
  total: string;
  discount: string;
  subTotal?: string;
  products: Array<OrderProduct>;
  isTrackingUploaded?: boolean;
  trackingInfo?: TrackingInfo;
}

export interface IOrderItem {
  id: string;
  name: string;
  image: string;
  quantity: number;
  orderNumber?: string;
  createdAt: string;
  status: string;
  total: string;
}

export interface Size {
  available: boolean;
  id: string;
  value: string;
  quantityAvailableForOrder: number;
}
export interface Color {
  name: string;
  value: string;
}

export interface Product {
  index: number;
  name: string;
  color: Color;
  price: string;
  images: Array<string>;
  sizes: Array<Size>;
}

export interface ProductsList {
  products: Array<Product>;
}

export type CartItem = {
  id: string;
  index: number;
  name: string;
  size: string;
  price: string;
  image: string;
  color: string;
  count: number;
  isOutOfStock?: boolean;
  available?: boolean;
};

export enum CartItemAction {
  add = 'add',
  remove = 'remove',
}

export type CheckoutCartItem = {
  itemPriceId: string;
  quantity: number;
};

export enum CheckoutTypeEnum {
  ACTIVATION = 'activation',
  UPGRADE = 'upgrade',
  ECOM = 'ecom',
}

import React from 'react';
import { Modal } from '../Modal';
import Button from '../Button/Button';
import './ConfirmationModal.scss';

interface IModalTypes {
  onConfirmClick: () => void;
  onRejectClick: () => void;
  isOpen?: boolean;
  info1?: string;
  info2?: string;
  confirmBtnText: string;
  rejectBtnText: string;
}

const ConfirmationModal = ({
  onConfirmClick,
  onRejectClick,
  isOpen = true,
  info1,
  info2,
  confirmBtnText,
  rejectBtnText,
}: IModalTypes) => {
  return (
    <Modal isOpen={isOpen}>
      <div className="confirmation-modal-container">
        <div className="confirmation-description">
          <p>{info1}</p>
          <p>{info2}</p>
        </div>
        <div className="confirmation-modal-btn-container">
          <Button label={rejectBtnText} styleKey="light" onClick={onRejectClick} />
          <Button label={confirmBtnText} styleKey="dark" onClick={onConfirmClick} />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;

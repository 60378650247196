import React, { MouseEvent } from 'react';
import { AuthProvider } from '../../types/auth';
import { authRedirect } from '../../services/API/UserAPI';
import { ReactComponent as GoogleLogo } from '../../assets/images/googleLogo.svg';
import { ReactComponent as AppleLogo } from '../../assets/images/appleLogo.svg';

interface SocialButtonProps {
  provider: AuthProvider;
  icon: React.ReactNode;
  isSignIn: boolean;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

const getNameButton = (isSignIn: boolean, provider: AuthProvider) =>
  `${isSignIn ? 'Sign In' : 'Continue'} with ${
    provider.charAt(0) + provider.slice(1).toLowerCase()
  }`;

const SocialButton = ({ provider, icon, isSignIn, onClick }: SocialButtonProps) => (
  <button type="button" name={provider} className="social-button" onClick={onClick}>
    {icon}
    <span>{getNameButton(isSignIn, provider)}</span>
  </button>
);

const SOCIAL_PROVIDERS = [
  {
    provider: AuthProvider.GOOGLE,
    icon: <GoogleLogo />,
  },
  {
    provider: AuthProvider.APPLE,
    icon: <AppleLogo />,
  },
];

const SocialAuthBlock = ({ isSignIn = false }: { isSignIn?: boolean }) => {
  const handleAuthRedirect = (event: MouseEvent<HTMLButtonElement>) => {
    const { name } = event.currentTarget as HTMLButtonElement;
    authRedirect(name as AuthProvider);
  };

  return (
    <div className="social-block">
      <div className="divider">
        <span>or</span>
      </div>

      <div className="social-buttons">
        {SOCIAL_PROVIDERS.map(button => (
          <SocialButton
            key={button.provider}
            provider={button.provider}
            icon={button.icon}
            isSignIn={isSignIn}
            onClick={handleAuthRedirect}
          />
        ))}
      </div>
    </div>
  );
};

export default SocialAuthBlock;

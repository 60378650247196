import React from 'react';

import './ConfirmationBlock.scss';
import Button from '../Button';
import warningSign from '../../assets/images/warningSign.svg';

interface IPropTypes {
  info: string;
  warning: string;
  continueButtonText: string;
  backButtonText: string;
  continueButtonClick: () => void;
  backButtonClick: () => void;
  withImage?: boolean;
}
function ConfirmationBlock({
  info,
  warning,
  continueButtonText,
  backButtonText,
  continueButtonClick,
  backButtonClick,
  withImage,
}: Readonly<IPropTypes>) {
  return (
    <div className="confirmation-block">
      {withImage && (
        <div className="image-block">
          <img className="warning-image" src={warningSign} alt="empty_img" />
        </div>
      )}
      <p>{info}</p>
      <p className="warning">{warning}</p>
      <div className="buttons">
        <Button
          onClick={continueButtonClick}
          className="button-item"
          label={continueButtonText}
          styleKey="light"
        />
        <Button
          onClick={backButtonClick}
          className="button-item"
          label={backButtonText}
          styleKey="dark"
        />
      </div>
    </div>
  );
}

export default ConfirmationBlock;

import React, { useState } from 'react';
import './NavDropdown.scss';
import { ReactComponent as ChevronDown } from '../../../assets/images/openListIcon.svg';
import { ILink } from '../../../types/nav';

interface INavDropdownProps {
  id: string;
  label: string;
  options?: ILink[];
}

const NavDropdown = ({ id, label, options }: INavDropdownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div>
      <div
        id={id}
        className="e-link-li"
        onClick={() => setIsOpen(prev => !prev)}
        aria-haspopup="true"
        aria-controls="dropdown-menu"
        aria-expanded={isOpen}
      >
        <span>
          {label}
          {'  '}
          <ChevronDown width="10" height="10" />
        </span>
      </div>
      {isOpen && (
        <ul
          id="dropdown-menu"
          role="menu"
          aria-labelledby={id}
          aria-orientation="vertical"
          className="dropdown"
        >
          {options?.map((option: ILink) => (
            <li className="e-link-li" key={option.link}>
              <a className={`e-link ${option.className}`} href={option.link}>
                {option.name}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default NavDropdown;

import React from 'react';

import './ProductItem.scss';
import { ProductNameTM } from '../../../components/ProductNameTM';
import { CartItem, CartItemAction } from '../../../types/products';
import { formatPrice } from '../../../utils';

interface ICartPropTypes {
  item: CartItem;
  changeCountItem(item: CartItem, action: CartItemAction): void;
  removeCartItem(item: CartItem): void;
}

function ProductItem({ item, changeCountItem, removeCartItem }: Readonly<ICartPropTypes>) {
  const plusItem = () => {
    if (item.available) {
      changeCountItem(item, CartItemAction.add);
    }
  };

  const minusItem = () => {
    if (!item.isOutOfStock) {
      changeCountItem(item, CartItemAction.remove);
    }
  };

  const removeItem = () => {
    removeCartItem(item);
  };

  return (
    <div className={`product-item ${item.isOutOfStock ? 'unavailable' : ''} `}>
      <div className="price-block">
        <ProductNameTM className="price-title" name={item.name} />
        {item.isOutOfStock ? (
          <div className="out-of-stock">Out of stock</div>
        ) : (
          <div className="price-value">
            &#36;{formatPrice(item.price)} x {item.count}
          </div>
        )}
      </div>
      <div className="product-content">
        <div className="product-images">
          <img src={item.image} alt="product_image" />
        </div>
        <div className="product-values">
          <div className="product-color">
            <span className="title">Color:</span>
            <span className="value">{item.color}</span>
          </div>
          <div className="product-size">
            <span className="title">Size:</span>
            <span className="value">{item.size}</span>
          </div>
          <div className="product-count-container">
            <div className="product-count">
              <span onClick={minusItem} className="sign">
                -
              </span>
              <span className="count">{item.count}</span>
              <span
                onClick={plusItem}
                className={`sign ${!item.available && !item.isOutOfStock ? 'disable' : ''}`}
              >
                +
              </span>
              <span onClick={removeItem} className="remove-button">
                Remove
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductItem;

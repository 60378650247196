import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useMutation } from '@apollo/client';
import './Referrals.scss';
import Button from '../../components/Button';
import { REFERRAL } from '../../services/ApolloClient';
import { ReactComponent as Logo } from '../../assets/images/smallEmLogo.svg';
import { trackReferFriendInitiatedEvent } from '../../services/DataPipeline/DataPipeline';

export const Referrals = () => {
  const [referralData, setReferralData] = useState({
    link: '',
    refereesCount: 0,
    refereesLimit: 0,
  });
  const [getReferralInfo] = useMutation(REFERRAL);

  useEffect(() => {
    (async () => {
      try {
        const res = await getReferralInfo();
        if (res.data?.createReferral?.referral) {
          const { link, refereesCount, refereesLimit, shortLink } =
            res.data.createReferral.referral;
          setReferralData({ link: shortLink || link, refereesCount, refereesLimit });
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [getReferralInfo]);

  const onCopyLink = () => {
    trackReferFriendInitiatedEvent({
      cta: 'Refer a Friend',
      source: 'Web',
    });
  };

  const copyLink = () => {
    onCopyLink();
    navigator.clipboard.writeText(referralData.link);
  };

  return (
    <>
      <Helmet>
        <title>Referral | Every Mother</title>
      </Helmet>
      <div className="referral-container">
        <div className="left-block">
          <div className="banner">
            <Logo className="logo" />
            <p className="text">30 Day Guest Pass</p>
          </div>
        </div>
        <div className="right-block">
          <h2>Know someone who could benefit from Every Mother?</h2>
          <p className="info">
            As an EM member, you can share up to five guest passes with friends. Share with a
            pregnant friend, a family member who just gave birth, or anyone you know that could
            benefit from quality core and pelvic floor care!
          </p>
          <div className="stats-container">
            <div className="stats-line" />
            <div className="stats-text">
              Passes <br /> Redeemed
            </div>
            <div className="stats-number">
              {referralData.refereesCount}/{referralData.refereesLimit}
            </div>
          </div>
          <div className="share-container">
            <div className="share-input">
              <div className="input-title">Share your link</div>
              <div className="input">
                <div onCopy={onCopyLink} className="input-link">
                  {referralData.link}
                </div>
              </div>
            </div>
            <div className="share-button">
              <Button
                onClick={copyLink}
                disabled={referralData.refereesCount === referralData.refereesLimit}
                width="90%"
                label="Copy"
                styleKey="dark"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import './Corset.scss';
import { Helmet } from 'react-helmet-async';
import Button from '../../../components/Button/Button';
import Cart from '../../../components/Cart/Cart';
import ShippingInfo from '../../../components/ShippingInfo/ShippingInfo';
import ReviewsSlider from '../../../components/ReviewsSlider/ReviewsSlider';
import { ProductNameTM } from '../../../components/ProductNameTM';
import { SizeGuide, SizesInfo } from '../../../components/SizeGuide';
import { Slider } from '../../../components/Slider';
import { WrapperBanner } from '../../../components/Banner';
import { useProducts } from '../../../contexts';
import { Details, Item, details, imageCaption } from './helpers';
import { TRACK_EVENT } from '../../../services/ApolloClient';
import { Size, Product } from '../../../types/products';
import { formatPrice, navigateWithReload } from '../../../utils';

function Corset() {
  const [selectedItem, setSelectedItem] = useState<Product | null>(null);
  const [selectedSize, setSelectedSize] = useState<Size | null>(null);
  const [isSizeError, setIsSizeError] = useState(false);
  const { addCartItem, cartItems, products, getProducts, totalCount } = useProducts();
  const [trackEvent] = useMutation(TRACK_EVENT);

  useEffect(() => {
    if (getProducts) {
      getProducts();
    }
  }, [getProducts]);

  useEffect(() => {
    if (products?.length) {
      setSelectedItem(prev => {
        if (!prev) {
          trackEvent({
            variables: {
              eventName: 'View Product page',
              properties: JSON.stringify({
                product_name: products[0]?.name,
              }),
            },
          });
        }

        return products[prev ? prev.index : 0];
      });
    }
  }, [products, trackEvent]);

  useEffect(() => {
    selectedItem?.sizes.forEach((size: Size): void => {
      if (size.id === selectedSize?.id) {
        setSelectedSize(size);
      }
    });
  }, [selectedItem, selectedSize?.id]);

  const addProductToCart = () => {
    if (selectedItem && selectedSize) {
      addCartItem({
        id: selectedSize.id,
        index: selectedItem.index,
        name: selectedItem.name,
        color: selectedItem.color.name,
        image: selectedItem.images[0],
        size: selectedSize.value,
        count: 1,
        price: selectedItem.price,
      });
      return true;
    }
    setIsSizeError(true);
    return false;
  };

  const navigateToCart = () => {
    if (!cartItems.length) addProductToCart();
    navigateWithReload('/cart/');
  };

  const handleColorChange = (value: Product) => {
    setSelectedItem(value);
    setSelectedSize(null);
  };

  const handleSizeChange = (size: Size) => {
    if (size.available) {
      setIsSizeError(false);
      setSelectedSize(size);
    }
  };

  return (
    <>
      <Helmet>
        <title>EMbody Core Support | Every Mother</title>
      </Helmet>

      <WrapperBanner>
        {products && selectedItem && (
          <>
            <div className="corset">
              <div className={`cart-block ${cartItems.length > 0 ? 'cart-block-fixed' : ''}`}>
                <div className="cart-item">
                  <Cart />
                </div>
              </div>
              <div className="product-block">
                <div className="product-info-1">
                  <ProductNameTM className="product-title-mobile" name={selectedItem.name} />
                  <div className="image-container">
                    <Slider key={selectedItem?.color?.name} images={selectedItem.images} />
                    <p className="image-caption">
                      {imageCaption[selectedItem?.color?.name.toLowerCase() ?? '']}
                    </p>
                  </div>
                  <div className="shipping">
                    <ShippingInfo />
                  </div>
                </div>
                <div className="product-info-2">
                  <ProductNameTM className="product-title" name={selectedItem.name} />
                  <div className="colors-price-block">
                    <div className="colors">
                      <span className="sub-title">Color: </span>
                      {products.map(it => (
                        <div
                          onClick={() => handleColorChange(it)}
                          style={{ backgroundColor: it.color.value }}
                          key={it.color.name}
                          className={`color-item ${
                            selectedItem.color.value === it.color.value ? 'color-item-active' : ''
                          }`}
                        >
                          <span className="tooltip">{it.color.name}</span>
                        </div>
                      ))}
                    </div>
                    <div className="price">&#36;{formatPrice(selectedItem.price)}</div>
                  </div>
                  <div className="sizes-block">
                    <div className="sizes-header">
                      <span className="sub-title">Size: </span>
                      <SizeGuide />
                    </div>
                    {isSizeError && <div className="size-error">Please select a size</div>}
                    <div className="sizes-list">
                      {selectedItem.sizes.map((size: Size) => (
                        <div
                          onClick={() => handleSizeChange(size)}
                          key={size.id}
                          className={`sizes-item
                        ${size.available ? '' : 'sizes-item-hidden'}
                        ${selectedSize?.id === size.id ? 'sizes-item-active' : ''}`}
                        >
                          {size.value}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="buttons-block">
                    <Button
                      onClick={addProductToCart}
                      width="100%"
                      styleKey="dark"
                      label="Add to cart"
                      className={selectedSize?.available ? 'add-to-cart-add-to-cart' : ''}
                      disabled={!selectedSize?.available}
                    />
                    <Button
                      onClick={navigateToCart}
                      width="100%"
                      styleKey="light"
                      label="Buy Now"
                      className={selectedSize?.available ? 'add-to-cart-buy-now' : ''}
                      disabled={!selectedSize?.available && !totalCount}
                    />
                  </div>
                  <div className="details-block">
                    <div className="details-info">
                      EMbody Core Support<sup>TM</sup> is the essential compression garment to
                      enhance core recovery. Designed to help draw together the rectus abdominis
                      muscles, the garment boosts recovery and accelerates healing of the core and
                      abdominal muscles through targeted 360-degree compression.
                    </div>
                    {details.map((it: Details) => (
                      <details key={it.title}>
                        <summary>{it.title}</summary>
                        <div className="details-section">
                          {it.items.map((item: Item) => (
                            <div key={item.text}>
                              {item.title && <p className="details-title">{item.title}</p>}
                              {item.text && (
                                <div
                                  className={`details-item ${item.title ? 'margin-l-30' : ''} ${
                                    !item.isParagraph ? 'details-item--marker' : ''
                                  }`}
                                >
                                  {item.text}
                                  {item?.subItems?.map((subItem: string) => (
                                    <div
                                      className="details-item details-item--marker margin-l-30"
                                      key={subItem}
                                    >
                                      {subItem}
                                    </div>
                                  ))}
                                </div>
                              )}
                              {item.isTable && (
                                <div className="sizes-info-wrapper">
                                  <SizesInfo />
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </details>
                    ))}
                  </div>
                  <div className="shipping-mobile">
                    <ShippingInfo />
                  </div>
                </div>
              </div>
            </div>
            <ReviewsSlider />
          </>
        )}
      </WrapperBanner>
    </>
  );
}

export default Corset;

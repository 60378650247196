import React, { ChangeEvent, useEffect, useState } from 'react';
import './InputField.scss';
import PasswordIcon from '../PwIcon/PwIcon';

interface IPropTypes {
  type?: string;
  placeholder?: string;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  label?: string;
  error?: string;
  id?: string;
  accept?: string;
  disabled?: boolean;
}

function InputField(props: Readonly<IPropTypes>) {
  const {
    type,
    placeholder,
    handleChange,
    value = '',
    error,
    id,
    label,
    accept,
    disabled = false,
  } = props;
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [passwordType, setPasswordType] = useState(type);
  const [errorClass, setErrorClass] = useState('');

  const passwordToggle = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
    } else {
      setPasswordType('password');
    }
    setIsPasswordShown(!isPasswordShown);
  };

  useEffect(() => {
    if (error || error === '') {
      setErrorClass('error');
    } else {
      setErrorClass('');
    }
  }, [error]);

  return (
    <div className="input-container">
      <label htmlFor={id}>
        {label && <span className="label">{label}</span>}
        <input
          id={id}
          name={id}
          onChange={handleChange}
          type={passwordType}
          className={`input ${errorClass}`}
          placeholder={placeholder}
          value={value}
          autoComplete="on"
          accept={accept}
          disabled={disabled}
        />
        {type === 'password' && (
          <PasswordIcon
            className={errorClass}
            isPasswordShown={isPasswordShown}
            passwordToggle={passwordToggle}
          />
        )}
      </label>
      {error && <span className="error-msg">{error}</span>}
    </div>
  );
}

export default InputField;

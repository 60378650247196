import React from 'react';
import './ProductNameTM.scss';

interface ProductNameTMProps {
  name: string;
  className?: string;
}
export const ProductNameTM = ({ name, className }: ProductNameTMProps) => {
  return (
    <h2 className={`product-name ${className ?? ''}`}>
      {name}
      <sup>TM</sup>
    </h2>
  );
};

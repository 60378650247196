const environments: any = {
  dev: {
    API_URL: 'http://localhost:8081/graphql/',
    USER_API_URL: 'http://localhost:8081',
    WORDPRESS_URL: 'http://localhost:8090/wp-json/operatic_tools/api',
  },
  staging: {
    API_URL: 'https://user-api.staging.every-mother.com/graphql/',
    USER_API_URL: 'https://user-api.staging.every-mother.com',
    WORDPRESS_URL: 'https://staging.every-mother.com/wp-json/operatic_tools/api',
  },
  prod: {
    API_URL: 'https://user-api.every-mother.com/graphql/',
    USER_API_URL: 'https://user-api.every-mother.com',
    WORDPRESS_URL: 'https://every-mother.com/wp-json/operatic_tools/api',
  },
};

export const config = process.env.REACT_APP_ENV
  ? environments[process.env.REACT_APP_ENV]
  : environments.dev;

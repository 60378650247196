import React, { ChangeEvent } from 'react';
import './Checkbox.scss';
import { ReactComponent as CheckIcon } from '../../assets/images/check.svg';

interface IPropTypes {
  id: string;
  label: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isChecked?: boolean;
}

const Checkbox = ({ id, label, handleChange, isChecked = false }: Readonly<IPropTypes>) => {
  return (
    <label className="checkbox" htmlFor={id}>
      <input id={id} type="checkbox" checked={isChecked} onChange={handleChange} />
      <span className="checkmark">{isChecked && <CheckIcon />}</span>
      {label}
    </label>
  );
};

export default Checkbox;

import { CartItem, CheckoutCartItem, OrderProduct } from '../types/products';

export const getGrossTotal = (products: Array<OrderProduct>): string => {
  const grossPrice: number = products.reduce(
    (accumulator: number, currentValue: OrderProduct) =>
      accumulator + Number(currentValue.price) * currentValue.quantity,
    0,
  );
  return Number(grossPrice).toFixed(2) ?? '';
};

export const formatPrice = (price: number | string): string => {
  const updatePrice = Number(price);

  if (!Number.isNaN(updatePrice)) {
    return updatePrice.toFixed(2);
  }
  return '0.00';
};

export const getProductsPurchaseItems = (cartItems: Array<CartItem>): Array<CheckoutCartItem> => {
  return cartItems.reduce((acc: Array<CheckoutCartItem>, it) => {
    if (!it.isOutOfStock) {
      acc.push({ itemPriceId: it.id, quantity: it.count });
    }
    return acc;
  }, []);
};

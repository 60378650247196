import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useQuery } from '@apollo/client';
import { ProfilePicture, EditProfileForm } from './components';
import { PROFILE_USER_INFO } from '../../services/ApolloClient';
import Spinner from '../../components/Spinner/Spinner';
import './EditProfile.scss';

const EditProfile = () => {
  const { data, loading } = useQuery(PROFILE_USER_INFO);

  if (loading) return <Spinner isDark />;

  return (
    <>
      <Helmet>
        <title>Edit profile | Every Mother</title>
      </Helmet>

      <div className="profile-edit-container">
        <div className="profile-edit-wrapper">
          <ProfilePicture url={data?.me?.profilePictureUrl || ''} />
          <EditProfileForm userInfo={data?.me} />
          {data?.me?.isPasswordSet && (
            <Link className="navigate-link" to="/app/change-password">
              change password
            </Link>
          )}
        </div>
      </div>
    </>
  );
};
export default EditProfile;

import React, { ReactNode } from 'react';
import { FlagProvider } from '@unleash/proxy-client-react';

const { REACT_APP_FT_PROXY_API_URL, REACT_APP_FT_PROXY_SECRET, REACT_APP_ENV } = process.env;

const config = {
  url: REACT_APP_FT_PROXY_API_URL ?? '',
  clientKey: REACT_APP_FT_PROXY_SECRET ?? '',
  disableRefresh: true,
  appName: 'everyMother',
  environment: REACT_APP_ENV ?? '',
};

export const FeatureToggleProvider = ({ children }: { children: ReactNode }) => {
  return <FlagProvider config={config}>{children}</FlagProvider>;
};

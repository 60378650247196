import React from 'react';
import { ReactComponent as GoogleLogo } from '../../../../assets/images/googleLogo.svg';
import { ReactComponent as FacebookLogo } from '../../../../assets/images/facebookLogo.svg';
import { ReactComponent as AppleLogo } from '../../../../assets/images/appleLogo.svg';
import { AuthProvider } from '../../../../types/auth';

export const ProviderIcon = ({ provider }: { provider: AuthProvider }) => {
  const providerIcons = {
    [AuthProvider.GOOGLE]: GoogleLogo,
    [AuthProvider.FACEBOOK]: FacebookLogo,
    [AuthProvider.APPLE]: AppleLogo,
  };
  const Icon = providerIcons[provider];

  return <Icon width={16} height={16} />;
};

import React from 'react';
import sizesGuideImg from '../../assets/images/sizesGuide.jpg';
import { TableSizes } from './TableSizes';

export const SizesInfo = () => {
  return (
    <div className="sizes-info-container">
      <img className="img-size-guide" src={sizesGuideImg} alt="size_guide_img" />
      <TableSizes />
    </div>
  );
};

import React, { ReactNode, useEffect, useState } from 'react';
import { Banner } from './Banner';
import { useBanner } from './useBanner';
import { useGeolocation } from '../../hooks';

export const textBanner = {
  promo: "Save $20 on EMbody Core Support during Mother's Month. Use promo code EMBODYSUPPORT",
  warning: '︎Warning: We only offer shipping services to United States.',
};

export const WrapperBanner = ({ children }: { children: ReactNode }) => {
  const { isPromo } = useBanner();
  const { country } = useGeolocation();
  const [isUSALocation, setIsUSALocation] = useState(country === 'US');

  useEffect(() => {
    setIsUSALocation(country === 'US');
  }, [country]);

  return (
    <>
      {(isPromo || !isUSALocation) && (
        <Banner
          classNames={!isUSALocation ? 'warn' : ''}
          text={isUSALocation ? textBanner.promo : textBanner.warning}
        />
      )}
      <div className={isPromo || !isUSALocation ? 'with-banner' : ''}>{children}</div>
    </>
  );
};

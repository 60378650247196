import { cleanLocalStorageItems } from './helpers';

type FieldsType = { [key: string]: string };

const getFields = (fieldsMap: FieldsType, itemKey: string) => {
  const data = localStorage.getItem(itemKey);
  if (data) {
    const fields = JSON.parse(data);
    return Object.keys(fields).reduce((acc, key) => {
      return { ...acc, [`${fieldsMap[key]}`]: fields[key] };
    }, {});
  }
  return null;
};

const getUtmFields = () => {
  const fieldsMap: FieldsType = {
    utm_source: 'utmSource',
    utm_medium: 'utmMedium',
    utm_campaign: 'utmCampaign',
    utm_term: 'utmTerm',
    utm_content: 'utmContent',
  };

  return getFields(fieldsMap, 'marketingFields');
};

const getHeadersUtmFields = () => {
  const fieldsMap: FieldsType = {
    utm_source: 'em-utm-source',
    utm_medium: 'em-utm-medium',
    utm_campaign: 'em-utm-campaign',
    utm_term: 'em-utm-term',
    utm_content: 'em-utm-content',
  };

  return getFields(fieldsMap, 'marketingFields');
};

const getImpactFields = () => {
  const fieldsMap: FieldsType = {
    irclickid: 'impactId',
    clickid: 'impactId',
    promo: 'impactPromo',
  };

  return getFields(fieldsMap, 'impactFields');
};

const removeFields = () => {
  cleanLocalStorageItems(['marketingFields', 'impactFields']);
};

export { getUtmFields, getImpactFields, removeFields, getHeadersUtmFields };

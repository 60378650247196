import React from 'react';
import './Banner.scss';

interface BannerProps {
  text: string;
  classNames: string;
}

export const Banner = ({ text, classNames }: BannerProps) => {
  return (
    <div className={`banner-wrapper ${classNames}`}>
      <div className="banner">
        <p>{text}</p>
      </div>
    </div>
  );
};

import React, { createContext, useState, useContext, useMemo } from 'react';

interface AppContextData {
  loading: boolean;
  setLoading: (status: boolean) => void;
}

const AppContext = createContext<AppContextData>({} as AppContextData);

export const AppProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const value = useMemo(() => {
    return {
      loading,
      setLoading,
    };
  }, [loading]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export function useApp() {
  return useContext(AppContext);
}

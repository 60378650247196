import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useSearchParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { loginFields } from '../../services/Constants/FormConstants';
import Form from '../../components/Form/Form';
import { EDataKey, TData } from '../../types/forms';
import { SIGNIN } from '../../services/ApolloClient';
import { useAuth, useApp, useProducts } from '../../contexts';
import { API } from '../../services/API/BaseAPI';
import { cleanLocalStorageItems, validateUrl } from '../../utils';
import SocialAuthBlock from '../CreateAccount/SocialAuthBlock';

function Login() {
  const [formError, setFormError] = useState({});
  const [signin] = useMutation(SIGNIN);
  const { login } = useAuth();
  const { setLoading } = useApp();
  const { getCheckoutUrl } = useProducts();
  const [searchParams] = useSearchParams();
  const redirectTo = validateUrl(searchParams.get('redirect_to'));
  const isProductRedirect = searchParams.get('fromProduct');
  localStorage.setItem('is_product_redirect', JSON.stringify(isProductRedirect));

  const handleSignin = async (formData: Partial<TData>) => {
    try {
      setLoading(true);
      setFormError({});
      const res = await signin({
        variables: {
          name: formData?.email?.trim(),
          password: formData.password,
        },
      });
      login(res.data?.login?.token);
      await API.post('auth-cookie');
      let redirectUrl;
      if (isProductRedirect) {
        redirectUrl = await getCheckoutUrl();
        cleanLocalStorageItems(['is_product_redirect']);
      } else {
        redirectUrl = redirectTo ?? `${window.location.origin}/app/your-path/`;
      }
      setLoading(false);
      window.location.href = redirectUrl;
    } catch (e) {
      setLoading(false);
      setFormError({
        message:
          'Your username or password is invalid. Please note, both email and password fields are case-sensitive.',
        fields: [EDataKey.email, EDataKey.password],
      });
    }
  };

  return (
    <div className="register-login-container">
      <Helmet>
        <title>Login | Every Mother</title>
      </Helmet>
      <div className="register-login-content">
        <Form
          fields={loginFields}
          handleForm={handleSignin}
          formError={formError}
          buttonLabel="Sign In with email"
        >
          <Link className="navigate-link forgot-password-link" to="/forgot-password">
            forgot password?
          </Link>
        </Form>
        <div className="form-links">
          <p>Not a Member? &nbsp;</p>
          {isProductRedirect ? (
            <Link className="navigate-link" to="/members/create-account">
              sign up now
            </Link>
          ) : (
            <a className="navigate-link" href="/members/join/">
              join now
            </a>
          )}
        </div>

        <SocialAuthBlock isSignIn />
      </div>
    </div>
  );
}

export default Login;

import React from 'react';

interface OrderTotalPriceProps {
  grossPrice?: string;
  totalPrice: string;
  discount: string;
}

export const OrderTotalPrice = ({ grossPrice, totalPrice, discount }: OrderTotalPriceProps) => {
  return (
    <div className="order-total-container">
      {discount !== '0.00' ? (
        <>
          <div className="row">
            <span>Subtotal:</span>
            <span>&#36;{grossPrice}</span>
          </div>
          <div className="row">
            <span>Discount:</span>
            <span>&#36;{discount}</span>
          </div>
        </>
      ) : null}

      <div className="row total-price">
        <span>Total:</span>
        <span>&#36;{totalPrice}</span>
      </div>
    </div>
  );
};

import React from 'react';
import RoutesList from './routes';
import { useAuth } from './contexts';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { AppLoader } from './components/AppLoader';
import './styles/App.scss';

function App() {
  const { signed } = useAuth();
  return (
    <div className="App">
      <AppLoader />
      <Header />
      <div className="page-container">{signed !== null && <RoutesList />}</div>
      <Footer />
    </div>
  );
}

export default App;

import React, { MouseEventHandler } from 'react';
import './PwIcon.scss';

interface IPropsPasswordIcon {
  isPasswordShown: boolean;
  passwordToggle: MouseEventHandler;
  className: string;
}
export default function PasswordIcon({
  isPasswordShown,
  passwordToggle,
  className,
}: IPropsPasswordIcon) {
  return isPasswordShown ? (
    <svg
      className={`pw-icon ${className}`}
      onClick={passwordToggle}
      key="show"
      width="40"
      height="40"
      viewBox="-3 -1 100 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="46.5" cy="26.5" r="16" fill="#000000" stroke="#000000" strokeWidth="3" />
      <circle cx="47" cy="16" r="5" fill="white" />
      <path
        d="M92.0091 25.996C91.8271 26.2046 91.6422 26.4171 91.4542 26.6332C83.6938 35.5488 70.6799 50.5 47 50.5C22.6306 50.5 9.54092 35.1171 1.90831 25.948C9.04089 16.4588 22.6126 1.5 47 1.5C70.8248 1.5 84.3011 17.0831 91.6671 25.6006C91.7826 25.7342 91.8966 25.866 92.0091 25.996Z"
        stroke="#000000"
        strokeWidth="3"
      />
      <line
        x1="93.1398"
        y1="3.59107"
        x2="1.59107"
        y2="48.8602"
        stroke="white"
        strokeWidth="8"
        strokeLinecap="round"
      />
      <line
        x1="93.4624"
        y1="3.49442"
        x2="1.49442"
        y2="50.5376"
        stroke="#000000"
        strokeWidth="5"
        strokeLinecap="round"
      />
    </svg>
  ) : (
    <svg
      key="no-show"
      className={`pw-icon ${className}`}
      onClick={passwordToggle}
      width="40"
      height="40"
      viewBox="-3 -1 100 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="46.5" cy="26.5" r="16" fill="#000000" stroke="#000000" strokeWidth="3" />
      <circle cx="47" cy="16" r="5" fill="white" />
      <path
        d="M92.0091 25.996C91.8271 26.2046 91.6422 26.4171 91.4542 26.6332C83.6938 35.5488 70.6799 50.5 47 50.5C22.6306 50.5 9.54092 35.1171 1.90831 25.948C9.04089 16.4588 22.6126 1.5 47 1.5C70.8248 1.5 84.3011 17.0831 91.6671 25.6006C91.7826 25.7342 91.8966 25.866 92.0091 25.996Z"
        stroke="#000000"
        strokeWidth="3"
      />
    </svg>
  );
}

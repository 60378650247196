import React from 'react';
import { Modal, useModal } from '../Modal';
import { SizesInfo } from './SizesInfo';
import './SizeGuide.scss';

const SIZE_GUIDE = {
  title: 'How to measure your waist circumference',
  description:
    'Please measure yourself to ensure proper fit for your EMbody Core Support garment. Once you have measured, you can find your best size in our chart below.',
  titleSteps: 'Measuring Tips',
  steps: [
    {
      numStep: 1,
      text: 'Stand up straight and exhale.',
    },
    {
      numStep: 2,
      text: 'Wrap a tape measure around your waist, a couple inches above the navel.',
    },
    {
      numStep: 3,
      text: 'Hold the tape measure flat against your body, not too tight, and take a reading.',
    },
    {
      numStep: 4,
      text: 'If measurement is between sizes, size down.',
    },
  ],
};

export const SizeGuide = () => {
  const { isOpen, closeModal, openModal } = useModal();

  return (
    <>
      <button type="button" className="guide-btn-text" onClick={openModal}>
        Find your size
      </button>
      <Modal isOpen={isOpen} onClose={closeModal}>
        <div className="guide-steps-container">
          <h2 className="guide-title">{SIZE_GUIDE.title}</h2>
          <p className="guide-description">{SIZE_GUIDE.description}</p>
          <h3 className="guide-steps-title">{SIZE_GUIDE.titleSteps}</h3>
          <ol className="guide-steps-list">
            {SIZE_GUIDE.steps.map(step => (
              <li key={step.numStep}>{step.text}</li>
            ))}
          </ol>
        </div>
        <SizesInfo />
      </Modal>
    </>
  );
};

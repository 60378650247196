export const SURVEY_SOURCE = 'website';

export enum SURVEY_NAME {
  METRO_PT_ONBOARDING = 'Metro PT Onboarding',
}

export enum SURVEY_TRIGGER {
  ONBOARDING = 'onboarding',
}

interface QuestionAnsweredContext {
  question_answered_count: number;
}

interface SurveyMeta {
  survey_version: number;
  source: string;
  survey_trigger: SURVEY_TRIGGER;
}

export interface QuestionAnsweredEvent {
  survey_name: SURVEY_NAME;
  question: string;
  answer: any;
  survey_meta: SurveyMeta;
  question_answered_context?: QuestionAnsweredContext;
}

export interface ReferFriendInitiatedEvent {
  cta: string;
  source: string;
}

export interface WebViewEvent {
  modal_viewed: string;
}

import React, { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import './Modal.scss';

interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  children: ReactNode;
  backgroundColor?: string;
}

function ModalPortal({ children }: { children: ReactNode }) {
  return createPortal(children, document.getElementById('modal-root') as HTMLElement);
}

export const Modal: FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  backgroundColor,
}: ModalProps) => {
  if (!isOpen) return null;

  return (
    <ModalPortal>
      <div data-testid="modal-overlay" className="modal-overlay" onClick={onClose}>
        <div className="modal-root">
          <div
            style={{ backgroundColor }}
            className="modal-container"
            onClick={e => e.stopPropagation()}
          >
            {onClose ? (
              <button
                type="button"
                data-testid="modal-close-btn"
                className="modal-close-btn ripple"
                onClick={onClose}
              />
            ) : null}
            <div className="modal-content">{children}</div>
          </div>
        </div>
      </div>
    </ModalPortal>
  );
};

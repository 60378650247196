import React, { createContext, ReactNode, useContext, useCallback, useState, useMemo } from 'react';
import { ErrorModal } from '../components/ErrorModal';
import { navigateWithReload } from '../utils';

interface InterfaceErrorModal {
  buttonText?: string;
  isOpen?: boolean;
  message?: string;
  navigationRoute?: string;
}
interface ErrorProviderValue {
  showError: (contentData?: InterfaceErrorModal) => void;
  closeError: () => void;
}

const defaultValue: ErrorProviderValue = {
  showError: () => null,
  closeError: () => null,
};

export const ErrorContext = createContext<ErrorProviderValue>(defaultValue);

const { Provider } = ErrorContext;

export function ErrorProvider({ children }: { readonly children: ReactNode }) {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState<null | InterfaceErrorModal>(null);

  const showError = useCallback(
    (data?: InterfaceErrorModal) => {
      setContent(data ?? {});
      setIsOpen(true);
    },
    [setContent, setIsOpen],
  );

  const closeError = useCallback(() => {
    setContent(null);
    setIsOpen(false);

    if (content?.navigationRoute) {
      navigateWithReload(content?.navigationRoute);
    }
  }, [content?.navigationRoute]);

  const value: ErrorProviderValue = useMemo(
    () => ({
      showError,
      closeError,
    }),
    [showError, closeError],
  );

  return (
    <Provider value={value}>
      {children}
      <ErrorModal isOpen={isOpen} onSubmit={closeError} {...content} />
    </Provider>
  );
}

export const useError = (): ErrorProviderValue => useContext(ErrorContext);

export const getBrowserVersion = () => {
  const { userAgent } = navigator;
  const browserName = userAgent.match(
    /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
  ) ?? [''];

  if (/trident/i.test(browserName[0])) {
    const rv = /\brv[ :]+(\d+)/g.exec(userAgent) ?? [];
    return `IE ${rv[1] || ''}`;
  }

  const version = browserName[2] ? browserName[2] : null;
  const browser = browserName[1] ? browserName[1] : null;

  if (browser === null || version === null) {
    return 'Unknown Browser';
  }

  return `${browser} ${version}`;
};

export const getBrowserPlatform = () => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return 'mobile web';
  }
  return `web`;
};

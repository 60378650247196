import React from 'react';
import { ReactComponent as CartIcon } from '../../assets/images/cart.svg';
import EmptyCartModal from './EmptyCartModal';
import { useProducts } from '../../contexts';
import { useModal } from '../Modal';
import './Cart.scss';
import { navigateWithReload } from '../../utils';

function Cart() {
  const { totalCount } = useProducts();
  const { isOpen, closeModal, openModal } = useModal();

  const navigateToCart = () => {
    if (totalCount) {
      navigateWithReload('/cart/');
    } else {
      openModal();
    }
  };

  return (
    <>
      <div className="cart" onClick={navigateToCart}>
        <div className="cart-icon">
          <CartIcon />
          {totalCount > 0 && <div className="cart-count">{totalCount}</div>}
        </div>
        <div className="cart-text">Cart</div>
      </div>
      <EmptyCartModal isOpen={isOpen} onClose={closeModal} />
    </>
  );
}

export default Cart;

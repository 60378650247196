import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useApp } from '../../contexts';
import { TRACK_CHECKOUT } from '../../services/ApolloClient';
import { cleanLocalStorageItems } from '../../utils';

function SuccessCheckout() {
  const { setLoading } = useApp();
  const [trackCheckout] = useLazyQuery(TRACK_CHECKOUT);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        cleanLocalStorageItems(['coupon']);
        await trackCheckout();
        window.location.href = `${window.location.origin}/app/change-stage/${window.location.search}`;
      } catch (e) {
        window.location.href = `${window.location.origin}/app/change-stage/${window.location.search}`;
      }
    })();
  }, [setLoading, trackCheckout]);

  return <div />;
}

export default SuccessCheckout;

import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useSearchParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  PASSWORD_MATCH_ERROR_MESSAGE,
  registerFields,
} from '../../services/Constants/FormConstants';
import { CreateAccountForm } from './CreateAccountForm';
import SocialAuthBlock from './SocialAuthBlock';
import { EDataKey, TData } from '../../types/forms';
import { SIGNUP, VERIFY_ORGANIZATION_TOKEN } from '../../services/ApolloClient';
import { useAuth, useApp } from '../../contexts';
import {
  cleanLocalStorageItems,
  getImpactFields,
  getLocalStorageItem,
  getOrgDetailsFromQueryString,
  getUtmFields,
  navigateWithReload,
  removeFields,
} from '../../utils';
import { API } from '../../services/API/BaseAPI';
import './CreateAccount.scss';

function CreateAccount() {
  const fields = registerFields;
  const [formError, setFormError] = useState({});
  const [signup] = useMutation(SIGNUP);
  const [verifyOrganizationToken] = useMutation(VERIFY_ORGANIZATION_TOKEN);
  const { login } = useAuth();
  const { setLoading } = useApp();
  const [searchParams] = useSearchParams();
  const [organizationDetails] = useState(() => getOrgDetailsFromQueryString(searchParams));
  const referralId = searchParams.get('referral_id');
  const level = searchParams.get('level');
  localStorage.setItem('payment_plan', JSON.stringify(level));
  localStorage.setItem('referral_id', JSON.stringify(referralId));

  const checkoutRedirect = (isEnterprise: boolean, isMetroPt: boolean) => {
    if (isEnterprise) {
      window.location.href = `${window.location.origin}/app/your-path/`;
      return;
    }

    const isProductRedirect = getLocalStorageItem('is_product_redirect');
    if (isProductRedirect) {
      cleanLocalStorageItems(['is_product_redirect']);
      window.location.href = `${window.location.origin}/products/checkout/?fromProduct=true`;
      return;
    }

    if (referralId) {
      cleanLocalStorageItems(['referral_id']);
      window.location.href = `${window.location.origin}/members/checkout/?referral_id=${referralId}`;
      return;
    }

    if (level) {
      const url = isMetroPt ? 'metro-pt' : 'checkout';
      window.location.href = `${window.location.origin}/members/${url}/?level=${level}&isNew=true`;
      return;
    }

    throw Error('Checkout error. Please try later.');
  };

  const shouldRedirectEnterpriseUserToLogin = useCallback(async (): Promise<boolean> => {
    if (!organizationDetails) {
      return false;
    }
    try {
      const res = await verifyOrganizationToken({
        variables: {
          organizationDetails,
        },
      });
      return !!res.data?.verifyOrganizationToken?.expired;
    } catch (e: unknown) {
      return false;
    }
  }, [organizationDetails, verifyOrganizationToken]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const shouldRedirect = await shouldRedirectEnterpriseUserToLogin();
      if (shouldRedirect) {
        navigateWithReload('/login/');
      }
      setLoading(false);
    })();
  }, [setLoading, shouldRedirectEnterpriseUserToLogin]);

  const handleSignup = async (formData: Partial<TData>) => {
    try {
      setLoading(true);
      setFormError({});
      if (formData.password !== formData.confirmPassword) {
        setFormError({
          message: PASSWORD_MATCH_ERROR_MESSAGE,
          fields: [EDataKey.password, EDataKey.confirmPassword],
        });
        setLoading(false);
        return;
      }
      const analyticsFields = getUtmFields();
      const impactFields = getImpactFields();

      const res = await signup({
        variables: {
          email: formData.email,
          password: formData.password,
          confirmPassword: formData.confirmPassword,
          analytics: analyticsFields,
          impact: impactFields,
          organizationDetails,
        },
      });
      removeFields();
      login(res.data?.signUp?.token);
      await API.post('auth-cookie');
      checkoutRedirect(res.data?.signUp?.isEnterprise, res.data?.signUp?.isMetroPt);
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      setFormError({
        message: e.message,
        fields: [EDataKey.email],
      });
    }
  };

  return (
    <div className="register-login-container">
      <Helmet>
        <title>Create Account | Every Mother</title>
      </Helmet>
      {referralId && (
        <div className="referral-block">
          <h1 className="title">Activate your free 30-day guest pass.</h1>
          <p className="info">
            Exercise safely during pregnancy, heal after birth, and resolve common core and pelvic
            floor issues - all access for 30 days.
          </p>
        </div>
      )}
      <div className={`register-login-content ${referralId && 'referral-margin'}`}>
        <CreateAccountForm fields={fields} handleForm={handleSignup} formError={formError} />
        {!referralId && (
          <div className="form-links">
            <p>Already have an account? &nbsp;</p>
            <Link className="navigate-link" to="/login">
              sign in
            </Link>
          </div>
        )}

        {!organizationDetails && <SocialAuthBlock />}

        {referralId && (
          <div className="referral-note">
            <p>
              By creating an account above, you consent to Every Mother’s Terms of Service and
              Privacy Policy. We use your email to provide you with news, updates and promotions.
            </p>
            <p>
              Free trial is for new subscribers only. After your 30-day trial ends, you will be
              charged a total of $59.99 for the first quarter. Your quarterly subscription will
              automatically renew for $59.99 every three months until you cancel. For more details
              on our payment terms and how to cancel, click{' '}
              <a href="https://every-mother.com/membership-policy">here</a>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default CreateAccount;

import { useCallback, useEffect, useState } from 'react';

const { REACT_APP_GOOGLE_API_KEY } = process.env;

interface GoogleGeolocationRes {
  lat: string;
  lng: string;
}

export const useGeolocation = () => {
  const [country, setCountry] = useState('US');

  const GoogleGeolocation = useCallback(async (): Promise<GoogleGeolocationRes> => {
    if (REACT_APP_GOOGLE_API_KEY) {
      const url = `https://www.googleapis.com/geolocation/v1/geolocate?key=${REACT_APP_GOOGLE_API_KEY}`;
      const response = await fetch(url, {
        method: 'POST',
      });
      const { location } = await response.json();

      if (location) {
        const { lat, lng } = location;
        return { lat, lng };
      }
    }

    return { lat: '', lng: '' };
  }, []);

  const fetchCountryName = useCallback(async (latitude, longitude): Promise<void> => {
    if (latitude && longitude) {
      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${REACT_APP_GOOGLE_API_KEY}`;
      const response = await fetch(url);
      const data = await response.json();
      if (data) {
        const countryName = data.results[0].address_components.find(
          (component: { types: string | string[] }) => component.types.includes('country'),
        ).short_name;
        setCountry(countryName);
      }
    } else {
      setCountry('');
    }
  }, []);

  useEffect(() => {
    GoogleGeolocation()
      .then((res: GoogleGeolocationRes) => fetchCountryName(res?.lat, res?.lng))
      .catch(error => {
        setCountry('');
        throw Error(error);
      });
  }, [GoogleGeolocation, fetchCountryName]);

  return {
    country,
  };
};

import React from 'react';

const TITLE = 'Core support size chart';
const HEADERS = ['Size', 'Dress (US)', 'Waist'];
const SIZES = [
  { size: 'XS', dress: '0-2', waist: '25-27"' },
  { size: 'S', dress: '4-6', waist: '28-30"' },
  { size: 'M', dress: '8-10', waist: '31-33"' },
  { size: 'L', dress: '12-14', waist: '34-37"' },
  { size: 'XL', dress: '16-18', waist: '38-41"' },
];

export const TableSizes = () => {
  return (
    <table className="guide-sizes-table">
      <thead>
        <tr className="row">
          <th className="cell table-title" colSpan={3}>
            {TITLE}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr className="row">
          {HEADERS.map(colHeader => (
            <td className="cell header-cell" key={colHeader}>
              {colHeader}
            </td>
          ))}
        </tr>
        {SIZES.map(row => (
          <tr className="row data-row" key={row.size}>
            <td className="cell">{row.size}</td>
            <td className="cell">{row.dress}</td>
            <td className="cell">{row.waist}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

import React from 'react';
import { ReactComponent as ShippingIcon } from '../../assets/images/shipping.svg';

import './ShippingInfo.scss';

function ShippingInfo() {
  return (
    <>
      <div className="shipping-info">
        <div className="shipping-icon">
          <ShippingIcon />
        </div>
        <div className="shipping-text">Shipping to United States</div>
        <div className="shipping-free">Free</div>
      </div>
      <a href="https://every-mother.com/shipping-and-returns" className="shipping-policy">
        Shipping and return policy
      </a>
    </>
  );
}

export default ShippingInfo;

import React from 'react';
import { ProductNameTM } from '../ProductNameTM';
import { formatDateString, formatPrice } from '../../utils';

interface IOrderItem {
  id: string;
  name: string;
  image: string;
  quantity: number;
  orderNumber?: string;
  createdAt: string;
  status: string;
  total: string;
}

interface OrderListProps {
  orders: Array<IOrderItem>;
  withLink?: boolean;
}

export const OrderList = ({ orders, withLink }: OrderListProps) => {
  return (
    <div className="orders-list">
      {orders.map((product: IOrderItem) => (
        <div key={product.id} className="item">
          <img src={product.image} alt={`${product.name}_image`} className="item-image" />
          <div className="item-details">
            <div className="top-section">
              {withLink ? (
                <a
                  className="product-name"
                  href={`/products/order-status/?orderId=${product.id}&orderNumber=${product.orderNumber}`}
                >
                  {product.name}
                  <sup>TM</sup>
                </a>
              ) : (
                <ProductNameTM className="product-name" name={product.name} />
              )}
              <span className="quantity">x {product.quantity}</span>
            </div>
            <div className="bottom-section">
              <span>
                Order date: <span>{formatDateString(product.createdAt)}</span>
              </span>
              <span className="status">{product.status}</span>
              <span>Price: &#36;{formatPrice(product.total)}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

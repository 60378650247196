import React from 'react';
import './Spinner.scss';

interface ISpinnerPropTypes {
  isDark?: boolean;
}

export default function Spinner({ isDark }: Readonly<ISpinnerPropTypes>) {
  return (
    <div className="spinner-layout">
      <div className="spinner-container">
        <div className={`loading-spinner ${isDark ? 'blue' : 'beige'}`} />
      </div>
    </div>
  );
}

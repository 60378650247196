import React, { createContext, useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import * as Sentry from '@sentry/react';
import { useLazyQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { getUserId, removeToken, setToken, refreshToken } from '../utils';
import { GET_USER_INFO } from '../services/ApolloClient';
import { getUserHash } from '../services/API/UserAPI';

interface AuthContextData {
  signed: boolean | null;
  login(token: string): void;
  logout(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [signed, setSigned] = useState<boolean | null>(null);
  const [cookies] = useCookies(['em_logged_in', 'em_subscription_platform']);
  const location = useLocation();
  const [getUserInfo] = useLazyQuery(GET_USER_INFO, {
    fetchPolicy: 'no-cache',
  });

  const logout = useCallback(() => {
    window.analytics?.reset();
    window.location.href = `${window.location.origin}/logout/`;
  }, []);

  const login = useCallback((token: string) => {
    setToken(token);
  }, []);

  const updateIntercomSettings = useCallback(
    async (id: string, platform: string) => {
      const accessType = platform !== 'none' ? 'all access' : 'education';
      const userInfo = await getUserInfo();
      const hashData = await getUserHash();

      if (userInfo?.data?.me) {
        const { email } = userInfo.data.me;
        window.Intercom('update', {
          user_id: id,
          email,
          user_hash: hashData?.user_hash ?? '',
          'access-type': accessType,
        });

        if (window.clarity) {
          window.clarity('set', 'internalId', id);
        }
      }
    },
    [getUserInfo],
  );

  useEffect(() => {
    if (cookies?.em_logged_in === '1') {
      const id = getUserId();
      if (id) {
        Sentry.setUser({ id });
        window.analytics?.identify(id);
        updateIntercomSettings(id, cookies?.em_subscription_platform);
      }
      setSigned(true);
    } else {
      setSigned(false);
      removeToken();
    }
  }, [cookies, updateIntercomSettings]);

  useEffect(() => {
    (async () => {
      try {
        if (!location.pathname.includes('authenticate-by-link')) {
          await refreshToken();
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [location]);

  const value = useMemo(() => {
    return {
      signed,
      login,
      logout,
    };
  }, [login, logout, signed]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth() {
  return useContext(AuthContext);
}

import React from 'react';
import logo from '../../assets/images/shortEmLogo.png';
import './Footer.scss';

export default function Footer() {
  const todayYear = new Date().getFullYear();

  return (
    <div className="footer-container">
      <div className="links-container">
        <a href="/" className="logo-link">
          <img className="footer-logo" src={logo} alt="Every Mother logo" />
        </a>
        <div className="footer-links">
          <a href="/about">About us</a>
          <a href="/empower">Blog</a>
          <a href="/press">Press</a>
          <a href="/stories">Success Stories</a>
          <a href="/ptpartners">PT/OT Partnerships</a>
          <a href="/faq">FAQs</a>
        </div>
        <div className="footer-links">
          <a href="/rise-menopauseprogram">RISE by Every Mother</a>
          <a href="/products/embody-core-support">EMbody Core Support</a>
          <a href="/membership-policy">Membership Policy</a>
          <a href="/terms-conditions">Terms and Conditions</a>
          <a href="/privacy-policy">Privacy Policy</a>
        </div>
        <div className="footer-links">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/every_mother/"
          >
            Instagram
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/EMbody.EveryMother/"
          >
            Facebook
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.tiktok.com/@everymother">
            TikTok
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/https://www.pinterest.com/EveryMother/"
          >
            Pinterest
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://youtube.com/thediamethod">
            Youtube
          </a>
        </div>
      </div>
      <div className="disclaimer-container">
        <p>© {todayYear} Sustainable Fitness Inc | Results cannot be guaranteed and may vary.</p>
        <br />
        <p>
          *In an HSS administered independent clinical trial of Every Mother&#39;s online exercise
          program, most participants (95%) reported that they would refer the program to a friend.
        </p>
      </div>
    </div>
  );
}

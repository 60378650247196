import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useQuery } from '@apollo/client';
import './MyAccount.scss';
import { MembershipTable } from './components/MembershipTable';
import { useAuth, useApp, useError } from '../../contexts';
import { GET_MEMBERSHIP, GET_USER_INFO, USER_ORDERS } from '../../services/ApolloClient';
import { BillingHistory } from './components/BillingHistory';
import { EcomOrders } from './components/Orders';
import { EMembershipStatus } from './components/types';
import { PaymentMethod } from './components/PaymentMethod';
import { UserAccountSection } from './components/UserAccountSection';

function MyAccount() {
  const [searchParams, setSearchParams] = useSearchParams();
  const isCancelled = searchParams.get('cancellation');
  const token = searchParams.get('token');
  const { showError } = useError();

  const { data: userData, loading: userDataLoading } = useQuery(GET_USER_INFO, {
    fetchPolicy: 'no-cache',
  });
  const {
    data: membershipData,
    loading: membershipDataLoading,
    refetch: membershipRefetch,
  } = useQuery(GET_MEMBERSHIP, {
    fetchPolicy: 'no-cache',
    variables: {
      forceUpdate: true,
    },
  });

  const { data: orderData, loading: orderDataLoading } = useQuery(USER_ORDERS, {
    fetchPolicy: 'no-cache',
  });
  const { login } = useAuth();
  const { setLoading } = useApp();

  const [isMembershipRefetchLoading, setIsMembershipRefetchLoading] = useState(false);

  useEffect(() => {
    if (token) {
      login(token);
    }
  }, [login, token]);

  useEffect(() => {
    setLoading(
      userDataLoading || membershipDataLoading || orderDataLoading || isMembershipRefetchLoading,
    );
  }, [
    isMembershipRefetchLoading,
    userDataLoading,
    membershipDataLoading,
    orderDataLoading,
    setLoading,
  ]);

  useEffect(() => {
    let limitRequests = 5;
    const fetchData = async () => {
      try {
        const refetchData = await membershipRefetch();
        if (
          refetchData?.data?.membership?.status === EMembershipStatus.cancelled ||
          !limitRequests
        ) {
          setIsMembershipRefetchLoading(false);
          setSearchParams([]);
        } else {
          limitRequests -= 1;
          fetchData();
        }
      } catch {
        showError();
      }
    };

    if (isCancelled) {
      setIsMembershipRefetchLoading(true);
      fetchData();
    }
  }, [isCancelled, membershipRefetch, setSearchParams, showError]);

  return (
    <div>
      <Helmet>
        <title>Account | Every Mother</title>
      </Helmet>
      {!userDataLoading && !membershipDataLoading && !orderDataLoading && (
        <div className="account-container">
          {membershipData && (
            <div className="membership-container">
              <h3>My Memberships</h3>
              <MembershipTable data={membershipData.membership} />
              <PaymentMethod data={membershipData.membership} />
              <BillingHistory />
            </div>
          )}
          {orderData?.userOrders && <EcomOrders orders={orderData?.userOrders} />}
          {userData?.me && (
            <UserAccountSection
              userData={userData?.me}
              membershipData={membershipData.membership}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default MyAccount;

import React, { useEffect, useState } from 'react';
import { OrderList } from '../../../../components/Order/OrderList';
import { IOrderItem } from '../../../../types/products';

interface IOrdersProps {
  orders: [
    {
      id: string;
      orderImage: string;
      orderNumber: string;
      orderGroupProductName: string;
      totalNumberOfItemsInTheOrder: number;
      createdAt: string;
      status: string;
      total: string;
    },
  ];
}

export function EcomOrders({ orders }: Readonly<IOrdersProps>) {
  const [formattedOrders, setFormattedOrders] = useState<Array<IOrderItem>>([]);

  useEffect(() => {
    if (orders.length) {
      const formatted = orders.map(it => {
        const res: IOrderItem = {
          id: it.id,
          name: it.orderGroupProductName,
          image: it.orderImage,
          orderNumber: it.orderNumber,
          quantity: it.totalNumberOfItemsInTheOrder,
          createdAt: it.createdAt,
          status: it.status,
          total: it.total,
        };
        return res;
      });

      setFormattedOrders(formatted);
    }
  }, [orders]);

  if (!formattedOrders.length) return null;

  return (
    <>
      <h3>My Orders</h3>
      <OrderList withLink orders={formattedOrders} />
    </>
  );
}

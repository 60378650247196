import React from 'react';
import { useMutation } from '@apollo/client';
import { EMembershipGroup, EMembershipStatus, EMigrationStrategy, TMembershipData } from '../types';
import { MIGRATE_PMPRO } from '../../../../services/ApolloClient';
import { useApp, useError } from '../../../../contexts';
import { navigateToExternalRoute } from '../../../../utils';

interface IPaymentMethodPropTypes {
  data: TMembershipData | null;
}

export const PaymentMethod = ({ data }: Readonly<IPaymentMethodPropTypes>) => {
  const [migratePmpro] = useMutation(MIGRATE_PMPRO);
  const { showError } = useError();
  const { setLoading } = useApp();

  if (
    data?.status !== EMembershipStatus.active ||
    ![EMembershipGroup.migration_paypal, EMembershipGroup.migration_braintree].includes(data.group)
  ) {
    return null;
  }

  const migratePmproSubscription = async () => {
    try {
      setLoading(true);
      const migrateRes = await migratePmpro({
        variables: {
          migrationStrategy: EMigrationStrategy.DURING_PAYMENT_METHOD_UPDATE,
        },
      });
      setLoading(false);
      navigateToExternalRoute(
        migrateRes?.data?.migratePmproSubscriptionToChargebee?.migrationResult?.hostedPageUrl,
      );
    } catch {
      showError();
      setLoading(false);
    }
  };

  return (
    <div>
      <span className="action-link" onClick={migratePmproSubscription}>
        Change Payment Method
      </span>
    </div>
  );
};

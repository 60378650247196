import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLazyQuery, useMutation } from '@apollo/client';
import './Cart.scss';
import ShippingInfo from '../../components/ShippingInfo/ShippingInfo';
import Button from '../../components/Button/Button';
import ProductItem from './components';
import { ProductNameTM } from '../../components/ProductNameTM';
import { CHECKOUT, TRACK_EVENT } from '../../services/ApolloClient';
import { useAuth, useApp, useProducts } from '../../contexts';
import { formatPrice, getProductsPurchaseItems, navigateWithReload } from '../../utils';

function Cart() {
  const { cartItems, changeCountItem, removeCartItem, totalCount, getProducts, products } =
    useProducts();
  const [checkout] = useLazyQuery(CHECKOUT);
  const { signed } = useAuth();
  const { setLoading } = useApp();
  const [trackEvent] = useMutation(TRACK_EVENT);

  useEffect(() => {
    if (!totalCount) {
      navigateWithReload('/products/embody-core-support/');
    } else if (getProducts && !products.length) {
      getProducts();
      trackEvent({
        variables: {
          eventName: 'View Cart',
          properties: JSON.stringify({}),
        },
      });
    }
  }, [totalCount, getProducts, products, trackEvent]);

  const checkoutRedirect = (isGuest?: boolean) => {
    try {
      trackEvent({
        variables: {
          eventName: 'eCom Initiate checkout',
          properties: JSON.stringify({
            is_guest_checkout: isGuest,
          }),
        },
      });
      if (isGuest || signed) {
        setLoading(true);
        checkout({
          variables: {
            cart: getProductsPurchaseItems(cartItems),
          },
        }).then(res => {
          setLoading(false);
          window.location.href = JSON.parse(res.data?.checkout?.hostedPage).url;
        });
      } else {
        navigateWithReload('/login/?fromProduct=true');
      }
    } catch (e: any) {
      setLoading(false);
      navigateWithReload('/cart/');
    }
  };

  return (
    <>
      <Helmet>
        <title>Cart | Every Mother</title>
      </Helmet>
      <div className="cart-container">
        <div className="product-info">
          <a href="/products/embody-core-support/">Back to products</a>
          {cartItems.map(item => (
            <ProductItem
              changeCountItem={changeCountItem}
              removeCartItem={removeCartItem}
              key={item.id}
              item={item}
            />
          ))}
        </div>
        <div className="payment-info">
          <h2>Your Cart</h2>
          <div className="price-block">
            <ProductNameTM className="price-title" name={cartItems[0]?.name} />
            <div className="price-value">
              &#36;{formatPrice(cartItems[0]?.price)} x {totalCount}
            </div>
          </div>
          <div className="price-block">
            <div className="price-title">Subtotal: </div>
            <div className="price-value">
              &#36;{formatPrice(Number(cartItems[0]?.price) * totalCount)}
            </div>
          </div>
          <div className="checkout-button">
            <Button
              onClick={() => checkoutRedirect(false)}
              width="100%"
              styleKey="dark"
              className="initiate-checkout-member"
              label={signed ? 'Checkout' : 'Member Checkout'}
            />
          </div>
          {!signed && (
            <div className="guest-block">
              <span className="guest-question">or</span>
              <div className="guest-button">
                <Button
                  onClick={() => checkoutRedirect(true)}
                  width="100%"
                  styleKey="light"
                  className="initiate-checkout-guest"
                  label="Continue as a Guest"
                />
              </div>
            </div>
          )}
          <div className="shipping-block">
            <ShippingInfo />
          </div>
        </div>
      </div>
    </>
  );
}

export default Cart;

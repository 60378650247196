import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import Login from '../pages/Login';
import ForgotPassword from '../pages/ForgotPassword';
import ChangePassword from '../pages/ChangePassword';
import CreateAccount from '../pages/CreateAccount';
import Corset from '../pages/Products/Corset';
import Cart from '../pages/Cart';
import ConfirmationPage from '../pages/Products/ConfirmationPage';
import OrderStatusPage from '../pages/Products/OrderStatusPage';
import GiveAway from '../pages/GiveAway';
import { AutoAuth, OAuth } from '../pages/AutoAuth';

const PreAuthRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/members/create-account" element={<CreateAccount />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/members/reset-password" element={<ChangePassword withKey />} />
      <Route path="/products/embody-core-support/" element={<Corset />} />
      <Route path="/products/confirmation/" element={<ConfirmationPage />} />
      <Route path="/products/order-status/" element={<OrderStatusPage />} />
      <Route path="/cart/" element={<Cart />} />
      <Route path="/giveaway/" element={<GiveAway />} />
      <Route path="/authenticate-by-link/" element={<AutoAuth />} />
      <Route path="/oauth/callback/" element={<OAuth />} />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};

export default PreAuthRoutes;

import React from 'react';
import Spinner from '../Spinner/Spinner';

export const Loader = ({ loading = true }: { loading?: boolean }) => (
  <div className="app-loader-container">
    {loading && (
      <div className="overlay">
        <Spinner isDark />
      </div>
    )}
  </div>
);

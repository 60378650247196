import React from 'react';
import { OrderTotalPrice } from './OrderTotalPrice';
import { OrderList } from './OrderList';
import { TrackingInfo } from './TrackingInfo';
import { getGrossTotal, navigateWithReload } from '../../utils';
import { IOrderItem, Order as OrderData } from '../../types/products';
import './Order.scss';
import Button from '../Button/Button';
import { useAuth } from '../../contexts';

interface OrderProps {
  title: string;
  description?: string;
  orderData: OrderData | null;
}

export const Order = ({ title, description, orderData }: OrderProps) => {
  const { signed } = useAuth();
  if (!orderData) return null;
  const {
    products,
    createdAt,
    status,
    total,
    discount,
    subTotal = '',
    trackingInfo,
    id,
  } = orderData;
  const grossTotal = getGrossTotal(products);

  const navigateToAccount = () => {
    navigateWithReload('/members/account/');
  };

  const formatOrders = () => {
    return products.map(it => {
      const res: IOrderItem = {
        id: id ?? it.name,
        name: it.name,
        image: it.image,
        quantity: it.quantity,
        createdAt,
        status,
        total,
      };
      return res;
    });
  };

  return (
    <div className="container">
      <div className="content-box">
        <h2 className="title">{title}</h2>
        {description && (
          <div className="description-block">
            <p>{description}</p>
            {signed && (
              <>
                <span>You can check all purchase information and delivery status in </span>
                <a href="/members/account/">your account</a>
              </>
            )}
          </div>
        )}
        <OrderList orders={formatOrders()} />
        <OrderTotalPrice
          grossPrice={subTotal || grossTotal}
          totalPrice={total ?? ''}
          discount={discount ?? ''}
        />
        <TrackingInfo data={trackingInfo ?? null} />
        {signed && (
          <Button
            onClick={navigateToAccount}
            width="200px"
            styleKey="dark"
            label="Go to your account"
          />
        )}
      </div>
    </div>
  );
};

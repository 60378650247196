import React from 'react';
import './GiveAway.scss';

function GiveAway() {
  return (
    <div className="giveaway-container">
      <h1>Thank you!</h1>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p className="info">You've officially entered the giveaway.</p>
    </div>
  );
}

export default GiveAway;

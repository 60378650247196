import React from 'react';
import './Cancellation.scss';
import { Helmet } from 'react-helmet-async';
import ReasonsModal from './components/ReasonsModal';
import { navigateWithReload } from '../../utils';
import { useModal } from '../../components/Modal';
import ConfirmationBlock from '../../components/ConfirmationBlock';

const INFO_MESSAGE =
  'Proceeding with cancellation will erase all progress tracking. If you rejoin at any time, your progress tracking will restart automatically.';
const WARNING_MESSAGE = 'Are you sure you want to continue with the cancellation?';

function Cancellation() {
  const { isOpen, closeModal, openModal } = useModal();

  const navigateToAccount = () => {
    navigateWithReload('/app/account/');
  };

  const navigateBack = () => {
    closeModal();
    navigateWithReload('/app/account/');
  };

  return (
    <div className="cancel-container">
      <Helmet>
        <title>Cancellation | Every Mother</title>
      </Helmet>
      {isOpen && <ReasonsModal onBackClick={navigateBack} />}
      <ConfirmationBlock
        info={INFO_MESSAGE}
        warning={WARNING_MESSAGE}
        continueButtonText="Yes, cancel this membership"
        continueButtonClick={openModal}
        backButtonText="No, keep this membership"
        backButtonClick={navigateToAccount}
      />
    </div>
  );
}

export default Cancellation;

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Order } from '../../components/Order';
import Spinner from '../../components/Spinner/Spinner';
import { ORDER_STATUS } from '../../services/ApolloClient';

const TITLE = 'Order: #';

const OrderStatusPage = () => {
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('orderId');
  const orderNumber = searchParams.get('orderNumber');
  const { data: orderData, loading } = useQuery(ORDER_STATUS, {
    variables: {
      orderId,
    },
  });

  return (
    <>
      <Helmet>
        <title>Order status | Every Mother</title>
      </Helmet>
      {!loading ? (
        <Order title={`${TITLE}${orderNumber}`} orderData={orderData?.orderStatus ?? null} />
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default OrderStatusPage;

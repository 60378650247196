import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CartItem, CartItemAction, Product, Size } from '../types/products';
import { TRACK_EVENT, ECOM_PRODUCTS, CHECKOUT } from '../services/ApolloClient';
import { useApp } from './AppContext';

interface ProductsContextData {
  cartItems: Array<CartItem>;
  addCartItem(item: CartItem): void;
  changeCountItem(item: CartItem, action: CartItemAction): void;
  removeCartItem(item: CartItem): void;
  cleanCart(): void;
  totalCount: number;
  products: Array<Product>;
  getProducts(): void;
  getCheckoutUrl(): Promise<string>;
}

const ProductsContext = createContext<ProductsContextData>({} as ProductsContextData);

export const ProductsProvider: React.FC = ({ children }) => {
  const [cartItems, setCartItems] = useState<Array<CartItem>>([]);
  const [productsData, setProductsData] = useState<Array<Product>>([]);
  const [isCartUpdated, setIsCartUpdated] = useState<boolean>(false);
  const [trackEvent] = useMutation(TRACK_EVENT);
  const [getProducts, { data: productsRes, loading, error }] = useLazyQuery(ECOM_PRODUCTS);
  const [checkout] = useLazyQuery(CHECKOUT);
  const { setLoading } = useApp();

  useEffect(() => {
    const products = localStorage.getItem('products');
    if (products) {
      setCartItems(JSON.parse(products));
    }
  }, []);

  const updateProducts = useCallback((products: Array<Product>, item: CartItem, value: number) => {
    let currentQuantity = 0;
    let quantityAvailable = 0;
    const newProductsData = products.map((it, index) => {
      let sizes: Array<Size> = [];
      if (index === item.index) {
        sizes = it.sizes.map(size => {
          if (size.id === item.id) {
            quantityAvailable = size.quantityAvailableForOrder + value;
            currentQuantity = quantityAvailable <= 0 ? size.quantityAvailableForOrder : item.count;
            return {
              ...size,
              quantityAvailableForOrder: quantityAvailable,
              available: quantityAvailable > 0,
            };
          }
          return { ...size };
        });
        return { ...it, sizes, index };
      }
      return { ...it, index };
    });
    setProductsData(newProductsData);
    return {
      currentQuantity,
      available: quantityAvailable > 0,
      newProductsData,
    };
  }, []);

  useEffect(() => {
    if (loading) setLoading(true);

    if (error) setLoading(false);

    if (productsRes && !isCartUpdated) {
      let allIsEmpty = true;
      setCartItems(prev => {
        let products = [...productsRes.products];
        const newItems = prev.map(cartIt => {
          const { currentQuantity, available, newProductsData } = updateProducts(
            products,
            cartIt,
            -cartIt.count,
          );
          if (currentQuantity > 0) {
            allIsEmpty = false;
          }

          products = newProductsData;

          return {
            ...cartIt,
            available,
            isOutOfStock: currentQuantity === 0,
          };
        });
        return allIsEmpty ? [] : newItems;
      });

      if (allIsEmpty) {
        setProductsData(
          productsRes.products.map((it: Product, index: number) => {
            return { ...it, index };
          }),
        );
      }
      setIsCartUpdated(true);
      setLoading(false);
    }
  }, [productsRes, isCartUpdated, loading, error, updateProducts, setLoading]);

  const addCartItem = useCallback(
    item => {
      let needAddItem = true;
      const { available } = updateProducts(productsData, item, -1);
      const newCartItems = cartItems.map(it => {
        if (it.id === item.id) {
          needAddItem = false;
          return { ...it, count: it.count + 1, available };
        }
        return it;
      });

      if (needAddItem) newCartItems.push({ ...item, available });

      setCartItems(newCartItems);
      localStorage.setItem('products', JSON.stringify(newCartItems));
      trackEvent({
        variables: {
          eventName: 'Add Product to cart',
          properties: JSON.stringify({ product_name: item.name }),
        },
      });
    },
    [cartItems, trackEvent, updateProducts, productsData],
  );

  const removeCartItem = useCallback(
    (item: CartItem) => {
      updateProducts(productsData, item, item.count);
      const newCartItems = cartItems.filter(it => {
        return it.id !== item.id;
      });

      setCartItems(newCartItems);
      localStorage.setItem('products', JSON.stringify(newCartItems));
      trackEvent({
        variables: {
          eventName: 'Remove Product from cart',
          properties: JSON.stringify({ product_name: item.name }),
        },
      });
    },
    [cartItems, productsData, trackEvent, updateProducts],
  );

  const changeCountItem = useCallback(
    (item: CartItem, action: CartItemAction) => {
      const value = action === CartItemAction.add ? 1 : -1;
      const { available } = updateProducts(productsData, item, -value);
      const newCartItems = cartItems.reduce((acc: Array<CartItem>, val) => {
        const newCount = val.count + value;
        if (val.id === item.id) {
          if (newCount > 0) {
            acc.push({ ...val, count: newCount, available });
          } else {
            trackEvent({
              variables: {
                eventName: 'Remove Product from cart',
                properties: JSON.stringify({ product_name: val.name }),
              },
            });
          }
        } else {
          acc.push(val);
        }
        return acc;
      }, []);

      setCartItems(newCartItems);
      localStorage.setItem('products', JSON.stringify(newCartItems));
    },
    [cartItems, productsData, trackEvent, updateProducts],
  );

  const getCheckoutUrl = useCallback(async () => {
    const checkoutRes = await checkout({
      variables: {
        cart: cartItems.map(it => {
          return { itemPriceId: it.id, quantity: it.count };
        }),
      },
    });
    return JSON.parse(checkoutRes.data?.checkout?.hostedPage).url;
  }, [cartItems, checkout]);

  const cleanCart = useCallback(() => localStorage.removeItem('products'), []);

  const totalCount = useMemo(() => {
    let count = 0;
    cartItems.forEach(it => {
      count += !it.isOutOfStock ? it.count : 0;
    });
    return count;
  }, [cartItems]);

  const value = useMemo(() => {
    return {
      cartItems,
      addCartItem,
      changeCountItem,
      removeCartItem,
      cleanCart,
      totalCount,
      products: productsData,
      getProducts,
      getCheckoutUrl,
    };
  }, [
    addCartItem,
    cartItems,
    changeCountItem,
    removeCartItem,
    totalCount,
    cleanCart,
    productsData,
    getProducts,
    getCheckoutUrl,
  ]);

  return <ProductsContext.Provider value={value}>{children}</ProductsContext.Provider>;
};

export function useProducts() {
  return useContext(ProductsContext);
}

import { AuthProvider } from '../../../types/auth';

export enum EMembershipStatus {
  trial = 'trial',
  active = 'active',
  expired = 'expired',
  cancelled = 'cancelled',
}

export enum EMembershipGroup {
  iap = 'iap',
  iab = 'iab',
  enterprise = 'enterprise',
  lifetime = 'lifetime',
  consumer = 'consumer',
  migration_braintree = 'migration_braintree',
  migration_paypal = 'migration_paypal',
  migration_free = 'migration_free',
}

export enum EMembershipPlatform {
  revenuecat = 'revenuecat',
  iap = 'iap',
  chargebee = 'chargebee',
  pmpro = 'pmpro',
}

export enum EMigrationStrategy {
  DURING_UNCANCEL = 'DURING_UNCANCEL',
  DURING_UPGRADE = 'DURING_UPGRADE',
  DURING_PAYMENT_METHOD_UPDATE = 'DURING_PAYMENT_METHOD_UPDATE',
}

export type TMethod = Record<string, { func: () => void; linkName: string }>;

export type TMembershipData = {
  status: EMembershipStatus;
  group: EMembershipGroup;
  platform: EMembershipPlatform;
  name: string;
  billingPeriod: number;
  billingPeriodUnit: string;
  timestamp: number;
};

export interface ExternalIdentifierData {
  externalUserId: string;
  externalUserEmail: string;
  provider: AuthProvider;
}

export interface UserAccountData {
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  isPasswordSet: boolean;
  profilePictureUrl: string;
  externalIdentifiers: Array<ExternalIdentifierData>;
}

import React, { useState } from 'react';
import { IField, TData } from '../../types/forms';
import Form from '../../components/Form/Form';

interface IFormPropTypes {
  fields: Array<IField>;
  handleForm: (data: Partial<TData>) => void;
  formError?: any;
}

const BUTTON_LABELS = {
  EMAIL_STEP: 'Continue with email',
  COMPLETE_STEP: 'Create Account',
} as const;
const NOTE = 'Please note, both email and password fields are case-sensitive.';

export const CreateAccountForm = ({ fields, handleForm, formError }: Readonly<IFormPropTypes>) => {
  const [visibleFields, setVisibleFields] = useState(() => (fields.length > 0 ? [fields[0]] : []));
  const isEmailStepOnly = visibleFields.length === 1;
  const showPasswordFields = () => setVisibleFields(fields);

  return (
    <Form
      title="Create Account"
      fields={visibleFields}
      handleForm={isEmailStepOnly ? showPasswordFields : handleForm}
      formError={formError}
      buttonLabel={isEmailStepOnly ? BUTTON_LABELS.EMAIL_STEP : BUTTON_LABELS.COMPLETE_STEP}
    >
      {!isEmailStepOnly && <p className="register-login-note">{NOTE}</p>}
    </Form>
  );
};

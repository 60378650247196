import React, { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CHECKOUT, ACTIVATION, GET_SURVEYS_INFO } from '../../services/ApolloClient';
import { useApp, useProducts } from '../../contexts';
import { CheckoutCartItem, CheckoutTypeEnum } from '../../types/products';
import { getLocalStorageItem, getProductsPurchaseItems } from '../../utils';

function Checkout() {
  const [searchParams] = useSearchParams();
  const [checkout] = useLazyQuery(CHECKOUT);
  const [activation] = useMutation(ACTIVATION);
  const { setLoading } = useApp();
  const { cartItems } = useProducts();
  const referral = searchParams.get('referral_id');

  const { data: surveyInfoData, loading: surveyInfoDataLoading } = useQuery(GET_SURVEYS_INFO, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    setLoading(true);
  }, [setLoading]);

  const checkoutRedirect = useCallback(
    async (
      priceId: string | null,
      cart: Array<CheckoutCartItem> | null,
      referralId: string | null = null,
      coupon: string | null = null,
    ) => {
      try {
        const checkoutRes = await checkout({
          variables: { priceId, cart, coupon, referralId },
        });

        if (checkoutRes.data?.checkout?.checkoutType === CheckoutTypeEnum.ACTIVATION) {
          activation();
        }

        window.location.href = JSON.parse(checkoutRes.data?.checkout?.hostedPage).url;
      } catch (e: any) {
        setLoading(false);
        window.location.href = `${window.location.origin}/app/your-path/`;
      }
    },
    [checkout, activation, setLoading],
  );

  useEffect(() => {
    if (surveyInfoDataLoading) {
      return;
    }

    if (surveyInfoData?.surveysInfo.showMetroPt) {
      window.location.href = `${
        window.location.origin
      }/members/metro-pt/?${searchParams.toString()}`;
      return;
    }

    const fromProduct = searchParams.get('fromProduct');
    if (fromProduct) {
      checkoutRedirect(null, getProductsPurchaseItems(cartItems));
      return;
    }

    if (referral) {
      checkoutRedirect(null, null, referral);
      return;
    }

    const level = searchParams.get('level');
    const coupon = getLocalStorageItem('coupon');
    if (level) {
      checkoutRedirect(level, null, null, coupon);
      return;
    }

    setLoading(false);
    window.location.href = `${window.location.origin}/app/your-path/`;
  }, [
    checkoutRedirect,
    searchParams,
    setLoading,
    cartItems,
    surveyInfoData,
    surveyInfoDataLoading,
    referral,
  ]);

  return <div />;
}

export default Checkout;

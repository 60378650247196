import React from 'react';
import { Modal } from '../Modal';
import Button from '../Button/Button';
import { ReactComponent as ErrorIcon } from '../../assets/images/warningSign.svg';
import './ErrorModal.scss';

export const DEFAULT_TITLE = 'Oops! Something went wrong.';
export const DEFAULT_BUTTON_TEXT = 'Go Back';

interface IErrorModal {
  buttonText?: string;
  secondaryButtonText?: string;
  isOpen?: boolean;
  isIcon?: boolean;
  title?: string;
  message?: string;
  onSubmit?: () => void;
  onSecondarySubmit?: () => void;
  customClassName?: string;
}
export const ErrorModal = ({
  isOpen = true,
  isIcon = true,
  title = DEFAULT_TITLE,
  message = '',
  buttonText = DEFAULT_BUTTON_TEXT,
  secondaryButtonText = '',
  onSecondarySubmit,
  onSubmit,
  customClassName,
}: IErrorModal) => (
  <Modal isOpen={isOpen}>
    <div className={`error-modal-container ${customClassName}`}>
      {isIcon && <ErrorIcon width={75} height={75} />}
      <h3 className="error-modal-title">{title}</h3>
      {message && <p className="error-modal-message">{message}</p>}
      {secondaryButtonText ? (
        <div className="error-buttons-block">
          <Button
            label={secondaryButtonText}
            styleKey="light"
            width="150px"
            onClick={onSecondarySubmit}
          />
          <Button label={buttonText} styleKey="dark" width="150px" onClick={onSubmit} />
        </div>
      ) : (
        <Button label={buttonText} styleKey="dark" width="150px" onClick={onSubmit} />
      )}
    </div>
  </Modal>
);

import React, { useEffect, useState } from 'react';
import './MetroPT.scss';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useQuery, useMutation } from '@apollo/client';
import { useApp } from '../../contexts';
import Dropdown from '../../components/Dropdown/Dropdown';
import Checkbox from '../../components/Checkbox/Checkbox';
import Button from '../../components/Button/Button';
import { useModal } from '../../components/Modal';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import { SURVEYS, GET_SURVEYS_INFO, SUBMIT_METRO_PT } from '../../services/ApolloClient';
import { DropdownItem } from '../../types/forms';
import { trackQuestionAnswered } from '../../services/DataPipeline/DataPipeline';
import { SURVEY_NAME, SURVEY_TRIGGER, SURVEY_SOURCE } from '../../services/DataPipeline/models';

interface Survey {
  clinics: Array<{
    name: string;
    id: number;
    therapists: Array<number>;
  }>;
  therapists: Array<{
    name: string;
    id: number;
    clinics: Array<number>;
  }>;
}

const MPT_CLINIC_QUESTION = 'Metro PT Clinic';
const MPT_THERAPIST_QUESTION = 'Metro PT Therapist';
const MPT_DATA_SHARE_CONSENT_QUESTION = 'Metro Data Share Consent';

function MetroPT() {
  const { isOpen, openModal } = useModal();
  const [searchParams] = useSearchParams();
  const [survey, setSurvey] = useState<Survey | null>(null);
  const [agreeToShare, setAgreeToShare] = useState<boolean>(false);
  const [selectedClinic, setSelectedClinic] = useState<DropdownItem | null>(null);
  const [selectedTherapist, setSelectedTherapist] = useState<DropdownItem | null>(null);
  const [submitMetroPt] = useMutation(SUBMIT_METRO_PT);

  const { data: surveyData, loading: surveyDataLoading } = useQuery(SURVEYS, {
    variables: {
      type: 'metro_pt',
    },
    fetchPolicy: 'no-cache',
  });

  const { data: surveyInfoData, loading: surveyInfoDataLoading } = useQuery(GET_SURVEYS_INFO, {
    fetchPolicy: 'no-cache',
  });
  const { setLoading } = useApp();

  useEffect(() => {
    if (surveyInfoData && !surveyInfoData?.surveysInfo.showMetroPt) {
      window.location.href = `${window.location.origin}/app/your-path/`;
    }
  }, [surveyInfoData]);

  useEffect(() => {
    setLoading(surveyDataLoading || surveyInfoDataLoading);
  }, [surveyDataLoading, surveyInfoDataLoading, setLoading]);

  useEffect(() => {
    if (surveyData) {
      setSurvey(JSON.parse(surveyData.surveys[0].surveyJson));
    }
  }, [surveyData]);

  const getClinics = () => {
    if (!survey) {
      return [];
    }

    if (!selectedClinic && selectedTherapist) {
      const therapist = survey.therapists.find(t => t.id === selectedTherapist.id);
      return survey.clinics.filter(clinic => therapist?.clinics.includes(clinic.id));
    }

    return survey.clinics;
  };

  const getTherapists = () => {
    if (!survey) {
      return [];
    }

    if (!selectedTherapist && selectedClinic) {
      const clinic = survey.clinics.find(c => c.id === selectedClinic.id);
      return survey.therapists.filter(therapist => clinic?.therapists.includes(therapist.id));
    }

    return survey.therapists;
  };

  const checkoutRedirect = () => {
    window.location.href = `${window.location.origin}/members/checkout/?${searchParams.toString()}`;
  };

  const trackQuestionAnsweredEvent = (question: string, answer: any, answerKey: string) => {
    trackQuestionAnswered(
      {
        survey_name: SURVEY_NAME.METRO_PT_ONBOARDING,
        question,
        answer,
        survey_meta: {
          survey_version: 1,
          source: SURVEY_SOURCE,
          survey_trigger: SURVEY_TRIGGER.ONBOARDING,
        },
        question_answered_context: { question_answered_count: 1 },
      },
      {
        [answerKey]: answer,
        pt_ot_partner: 'Metro PT',
      },
    );
  };

  const submitSurvey = async (isConfirmed: boolean) => {
    await submitMetroPt();
    trackQuestionAnsweredEvent(
      MPT_DATA_SHARE_CONSENT_QUESTION,
      isConfirmed,
      'metro_data_share_consent',
    );
    trackQuestionAnsweredEvent(
      MPT_THERAPIST_QUESTION,
      selectedTherapist?.name,
      'metro_pt_therapist',
    );
    trackQuestionAnsweredEvent(MPT_CLINIC_QUESTION, selectedClinic?.name, 'metro_pt_clinic');
    checkoutRedirect();
  };

  const onContinuePress = async () => {
    if (!selectedClinic || !selectedTherapist) {
      return;
    }

    if (agreeToShare) {
      await submitSurvey(agreeToShare);
      return;
    }
    openModal();
  };

  const onClinicChange = (item: DropdownItem) => {
    if (selectedClinic && selectedTherapist) {
      setSelectedTherapist(null);
    }
    setSelectedClinic(item);
  };

  const onTherapistChange = (item: DropdownItem) => {
    if (selectedClinic && selectedTherapist) {
      setSelectedClinic(null);
    }
    setSelectedTherapist(item);
  };

  return (
    <div className="metro-pt-container">
      <Helmet>
        <title>Metro PT | Every Mother</title>
      </Helmet>

      <ConfirmationModal
        onConfirmClick={() => submitSurvey(true)}
        onRejectClick={() => submitSurvey(false)}
        isOpen={isOpen}
        info1="Sharing your Every Mother activity with your practitioner will help them understand what
        you do in between appointments and help you make progress more quickly."
        info2="Consent to share your Every Mother activity?"
        confirmBtnText="I consent"
        rejectBtnText="I do not consent"
      />

      <div className="metro-pt-content">
        <h2 className="header">
          Gain more benefits from Every Mother by sharing your progress with your Metro PT/OT
        </h2>

        <Dropdown
          placeholder="Select Clinic"
          options={getClinics()}
          value={selectedClinic}
          handleChange={onClinicChange}
          id="metro-pt-clinic"
          label="My PT/OT partner"
          isSearchable
        />
        <Dropdown
          placeholder="Select Therapist"
          options={getTherapists()}
          value={selectedTherapist}
          handleChange={onTherapistChange}
          id="metro-pt-therapist"
          isSearchable
        />

        <Checkbox
          id="metro-pt-checkbox"
          label="I hereby consent to share my Every Mother Data with Metro PT/OT"
          handleChange={event => setAgreeToShare(event.target.checked)}
          isChecked={agreeToShare}
        />

        <div className="details-block">
          <div className="details-info">
            Every Mother Data includes shared information and activity on the Every Mother platform
            from time to time, including for example, events such as video starts on the Every
            Mother platform. Every Mother Data submitted will not be anonymous.
          </div>

          <div className="details-info">
            I am sharing my Every Mother Data with Metro PT for the purpose of evaluation by my
            Metro PT therapist, and this data will not be shared outside of Metro PT or Every
            Mother.
          </div>
        </div>
        <Button
          styleKey="dark"
          label="Continue"
          width="85%"
          height="38px"
          onClick={onContinuePress}
        />
      </div>
    </div>
  );
}

export default MetroPT;

import { gql } from '@apollo/client';

const SIGNUP = gql`
  mutation signUp(
    $analytics: Analytics = null
    $impact: Impact = null
    $confirmPassword: String!
    $email: Email!
    $password: String!
    $organizationDetails: OrganizationDetailsInput = null
  ) {
    signUp(
      analytics: $analytics
      impact: $impact
      confirmPassword: $confirmPassword
      email: $email
      password: $password
      organizationDetails: $organizationDetails
    ) {
      token
      isEnterprise
      isMetroPt
    }
  }
`;

const FINALIZE_REGISTRATION = gql`
  mutation finalizeRegistration($impact: Impact = null) {
    finalizeRegistration(impact: $impact) {
      isMetroPt
    }
  }
`;

const SIGNIN = gql`
  mutation login($name: String!, $password: String!) {
    login(name: $name, password: $password) {
      token
    }
  }
`;

const CHANGE_PASSWORD_WITH_KEY = gql`
  mutation changePasswordByActivationKey(
    $token: String!
    $newPassword: Password!
    $newPasswordConfirm: Password!
  ) {
    changePasswordByActivationKey(
      token: $token
      newPassword: $newPassword
      newPasswordConfirm: $newPasswordConfirm
    ) {
      ok
    }
  }
`;

const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $currentPassword: String!
    $newPassword: Password!
    $newPasswordConfirm: Password!
  ) {
    changePassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
      newPasswordConfirm: $newPasswordConfirm
    ) {
      ok
    }
  }
`;

const SET_PASSWORD = gql`
  mutation setPassword($newPassword: Password!, $newPasswordConfirm: Password!) {
    setPassword(newPassword: $newPassword, newPasswordConfirm: $newPasswordConfirm) {
      ok
    }
  }
`;

const REFRESH_TOKEN = gql`
  mutation refreshToken($token: String!) {
    refreshToken(token: $token) {
      token
    }
  }
`;

const RESET_PASSWORD = gql`
  mutation resetPassword($name: String!) {
    resetPassword(name: $name) {
      ok
    }
  }
`;

const TRACK_EVENT = gql`
  mutation trackEvent($eventName: String!, $properties: JSONString!) {
    trackEvent(eventName: $eventName, properties: $properties) {
      ok
    }
  }
`;

const ACTIVATION = gql`
  mutation activation {
    activation {
      ok
    }
  }
`;

const UPDATE_PROFILE = gql`
  mutation updateProfile($username: String, $email: Email, $lastName: String, $firstName: String) {
    updateProfile(username: $username, email: $email, lastName: $lastName, firstName: $firstName) {
      user {
        username
        email
        lastName
        firstName
      }
    }
  }
`;

const UPLOAD_PROFILE_IMAGE = gql`
  mutation updateProfile($file: Upload!) {
    updateProfile(profilePicture: $file) {
      user {
        profilePictureUrl
      }
    }
  }
`;

const CANCEL_SUBSCRIPTION = gql`
  mutation cancelSubscription($cancellationReason: String!) {
    cancelSubscription(cancellationReason: $cancellationReason) {
      ok
    }
  }
`;

const REMOVE_PROFILE = gql`
  mutation removeProfile {
    removeProfile {
      ok
    }
  }
`;

const UNCANCEL_SUBSCRIPTION = gql`
  mutation uncancelSubscription {
    uncancelSubscription {
      ok
    }
  }
`;

const MIGRATE_PMPRO = gql`
  mutation migratePmproSubscriptionToChargebee(
    $chargebeePlanId: String = null
    $migrationStrategy: MigrationStrategy!
  ) {
    migratePmproSubscriptionToChargebee(
      chargebeePlanId: $chargebeePlanId
      migrationStrategy: $migrationStrategy
    ) {
      ok
      migrationResult {
        hostedPageUrl
      }
    }
  }
`;

const VERIFY_ORGANIZATION_TOKEN = gql`
  mutation verifyOrganizationToken($organizationDetails: OrganizationDetailsInput!) {
    verifyOrganizationToken(organizationDetails: $organizationDetails) {
      expired
    }
  }
`;

const SUBMIT_METRO_PT = gql`
  mutation submitMetroPt {
    submitMetroPt {
      ok
    }
  }
`;

const REFERRAL = gql`
  mutation createReferral {
    createReferral {
      referral {
        refereesCount
        refereesLimit
        link
        shortLink
      }
    }
  }
`;

export {
  SIGNUP,
  SIGNIN,
  REFRESH_TOKEN,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_WITH_KEY,
  RESET_PASSWORD,
  SET_PASSWORD,
  TRACK_EVENT,
  ACTIVATION,
  UPDATE_PROFILE,
  UPLOAD_PROFILE_IMAGE,
  CANCEL_SUBSCRIPTION,
  REMOVE_PROFILE,
  UNCANCEL_SUBSCRIPTION,
  MIGRATE_PMPRO,
  VERIFY_ORGANIZATION_TOKEN,
  SUBMIT_METRO_PT,
  REFERRAL,
  FINALIZE_REGISTRATION,
};

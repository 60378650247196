import { gql } from '@apollo/client';

const CHECKOUT = gql`
  query checkout(
    $priceId: String = null
    $cart: [CartItem] = null
    $coupon: String = null
    $referralId: UUID = null
  ) {
    checkout(cart: $cart, priceId: $priceId, coupon: $coupon, referralId: $referralId) {
      hostedPage
      checkoutType
    }
  }
`;

const TRACK_CHECKOUT = gql`
  query trackCheckoutRedirect {
    trackCheckoutRedirect
  }
`;

const ECOM_PRODUCTS = gql`
  query products {
    products {
      name
      color {
        name
        value
      }
      price
      images
      sizes {
        id
        available
        value
        quantityAvailableForOrder
      }
    }
  }
`;

const ORDER_STATUS = gql`
  query orderStatus($orderId: String!) {
    orderStatus(orderId: $orderId) {
      id
      total
      createdAt
      status
      discount
      products {
        name
        color
        size
        price
        quantity
        image
      }
      isTrackingUploaded
      trackingInfo {
        carrier
        trackingUrl
        trackingNumber
        carrierService
      }
    }
  }
`;

const CHARGEBEE_INVOICE = gql`
  query chargebeeInvoice($hostedPageId: String!) {
    chargebeeInvoice(hostedPageId: $hostedPageId) {
      invoiceId
      customerId
      status
      billingAddressEmail
      shippingAddressEmail
      totalCostItems
      totalDiscountAmount
      totalAmount
      products {
        name
        color
        size
        image
        price
        quantity
        discount
      }
    }
  }
`;

const SELF_SERVE_PORTAL = gql`
  query selfServePortal {
    selfServePortal {
      session
    }
  }
`;

const GET_USER_INFO = gql`
  query getUserInfo {
    me {
      username
      email
      firstName
      lastName
      isPasswordSet
      externalIdentifiers {
        externalUserId
        externalUserEmail
        provider
      }
    }
  }
`;

const GET_REFERRAL_DATA = gql`
  query getReferralData($referralId: UUID!) {
    referral(referralId: $referralId) {
      status
    }
  }
`;

const GET_MEMBERSHIP = gql`
  query getMembership($forceUpdate: Boolean = false) {
    membership(forceUpdate: $forceUpdate) {
      group
      platform
      status
      name
      billingPeriod
      billingPeriodUnit
      timestamp
    }
  }
`;

const PROFILE_USER_INFO = gql`
  query me {
    me {
      id
      username
      lastName
      firstName
      email
      profilePictureUrl
      isPasswordSet
      externalIdentifiers {
        externalUserId
        externalUserEmail
        provider
      }
    }
  }
`;

const GET_BILLING_HISTORY = gql`
  query billingHistory {
    billingHistory {
      id
      name
      amount
      timestamp
    }
  }
`;

const GET_INVOICE_PDF = gql`
  query InvoiceAsPdf($invoiceId: String!) {
    invoiceAsPdf(invoiceId: $invoiceId) {
      downloadUrl
    }
  }
`;

const USER_ORDERS = gql`
  query userOrders {
    userOrders {
      id
      orderGroupProductName
      orderNumber
      createdAt
      status
      total
      totalNumberOfItemsInTheOrder
      orderImage
    }
  }
`;

const GET_PRECANCEL_URL = gql`
  query precancel {
    precancel {
      url
    }
  }
`;

const SURVEYS = gql`
  query surveys($type: SurveyTypeEnum!) {
    surveys(type: $type) {
      id
      type
      surveyJson
    }
  }
`;

const GET_SURVEYS_INFO = gql`
  query surveysInfo {
    surveysInfo {
      showMetroPt
    }
  }
`;

export {
  CHECKOUT,
  TRACK_CHECKOUT,
  ECOM_PRODUCTS,
  ORDER_STATUS,
  CHARGEBEE_INVOICE,
  SELF_SERVE_PORTAL,
  GET_USER_INFO,
  GET_REFERRAL_DATA,
  GET_MEMBERSHIP,
  PROFILE_USER_INFO,
  GET_BILLING_HISTORY,
  GET_INVOICE_PDF,
  USER_ORDERS,
  GET_PRECANCEL_URL,
  SURVEYS,
  GET_SURVEYS_INFO,
};

import client from './ApolloClient';
import { REFRESH_TOKEN } from './Mutations';

const refreshRequest = (token: string) => {
  return client
    .mutate({
      mutation: REFRESH_TOKEN,
      variables: {
        token,
      },
    })
    .then(result => {
      if (result.errors) {
        throw new Error(result.errors[0].message);
      }
      return { success: true, ...result.data };
    })
    .catch(e => {
      return { success: false, error: e };
    });
};

export { refreshRequest };

export enum EPath {
  login = 'login',
  register = 'register',
  passwordReset = 'reset-password',
}

export enum EDataKey {
  email = 'email',
  password = 'password',
  currentPassword = 'currentPassword',
  newPassword = 'newPassword',
  confirmPassword = 'confirmPassword',
  firstName = 'firstName',
  lastName = 'lastName',
  username = 'username',
}

export interface IField {
  label: string;
  key: EDataKey;
  type: string;
  placeholder: string;
  error?: string;
  isDisabled?: boolean;
}

export type TData = Record<EDataKey, string>;

export type FieldTemplates = Record<EDataKey, IField>;

export interface DropdownItem {
  name: string;
  id: number;
}
